import { useMutation } from "react-query";
import { apiClient } from "../utils/ApiClient";
import { Endpoints } from "../utils/endpoints";

const createJob = async (data) => {
  const response = await apiClient.post(Endpoints.employerCreateJob, data);

  if (response.status == 'error') {
    throw new Error(response.message);
  }

  return response;
}

export const useEmployerCreateJob = () => {
  return useMutation(
    createJob
  )
}
