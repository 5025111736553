import React, { useMemo } from "react";
import { Pagination, Table } from "antd";
import { useEmployerGetAllJobs } from "../../../../../../hooks/useEmployerGetAllJobs";

export const CompletedJobs = () => {
  const { data: completedJobs } = useEmployerGetAllJobs('completed');
  const completedJobsDataSource = useMemo(() => {
    if (!completedJobs) return [];

    const options = [];

    completedJobs?.data?.map((job, index) => {
      options.push({
        id: index + 1,
        jobId: job.id,
        name: job.title,
        date: job.date.substring(0, 10),
        amount: job.amount
      })
    })

    return options;
  }, [completedJobs]);

  const completedColumns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
    },
  ];
  return (
    <div className='employers-job__table'>
      <Table
        columns={completedColumns}
        dataSource={completedJobsDataSource}
        pagination={
          {
            hideOnSinglePage: true
          }
        }
      />

      {/*<Pagination defaultCurrent={1} total={50} className='employers-job__table__pagination' />*/}
    </div>
  );
}
