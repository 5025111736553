import React, { useState, useMemo } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Table, Pagination } from "antd";
import { Navbar } from '../common-blocks/navbar';
import { Footer } from '../common-blocks/footer';
import { ViewJob } from "./blocks/viewJob";
import './index.scss';
import { useJobSeekersGetMyJobs } from "../../../../hooks/queries/useJobSeekersGetMyJobs";
import { JobDetails } from "./blocks/job-details";

export const MyJobs = () => {
  const [tabKey, setTabKey] = useState('active');
  const [jobData, setJobData] = useState({});
  const [isViewJob, setIsViewJob] = useState(false);

  const { data: jobSeekersAppliedJobs, isLoading } = useJobSeekersGetMyJobs();

  const appliedJobs = useMemo(() => {
    if (!jobSeekersAppliedJobs || !jobSeekersAppliedJobs.hasOwnProperty('data')) return [];

    return jobSeekersAppliedJobs.data.map(({ job }, index) => ({
      id: index + 1,
      name: job.title,
      date: job.date.substring(0, 10),
      price: job.amount,
      job
    }))

  }, [jobSeekersAppliedJobs]);
  const activeColumns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: '',
      dataIndex: 'view',
      key: 'view',
      render: (_, data) => (
        <>
          {/*<button*/}
          {/*  className='employers-job__tab__view'*/}
          {/*  onClick={() => {*/}
          {/*    console.log('>>>>', data);*/}
          {/*    openViewData(data)*/}
          {/*  }}*/}
          {/*>*/}
          {/*  View*/}
          {/*</button>*/}
          <JobDetails job={data} />
        </>
      )
    },
  ];
  const completedColumns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
    }
  ];

  const completedDataSource = [
    {
      id: 1,
      name: 'Lead Guitarist Needed',
      startDate: '25-01-2022',
      endDate: '01-02-2022',
      price: '$200'
    },
    {
      id: 2,
      name: 'Keyboardist Needed',
      startDate: '25-01-2022',
      endDate: '01-02-2022',
      price: '$200'
    },
    {
      id: 3,
      name: 'MC at Wedding',
      startDate: '25-01-2022',
      endDate: '01-02-2022',
      price: '$200'
    },
    {
      id: 4,
      name: 'Drummer for Special Service at RCCG',
      startDate: '25-01-2022',
      endDate: '01-02-2022',
      price: '$200'
    },
    {
      id: 5,
      name: 'Exclusive DJ for Club',
      startDate: '25-01-2022',
      endDate: '01-02-2022',
      price: '$200'
    },
    {
      id: 6,
      name: 'MC at Birthday Party',
      startDate: '25-01-2022',
      endDate: '01-02-2022',
      price: '$200'
    },
    {
      id: 7,
      name: 'Backup Singer for Adekunle Gold',
      startDate: '25-01-2022',
      endDate: '01-02-2022',
      price: '$200'
    },
    {
      id: 8,
      name: 'Backup Singer for Simi',
      startDate: '25-01-2022',
      endDate: '01-02-2022',
      price: '$200'
    },
    {
      id: 9,
      name: 'Bass Guitarist Needed',
      startDate: '25-01-2022',
      endDate: '01-02-2022',
      price: '$200'
    },
    {
      id: 10,
      name: 'Lead Guitarist Needed',
      startDate: '25-01-2022',
      endDate: '01-02-2022',
      price: '$200'
    },
  ];

  const openViewData = (data) => {
    setIsViewJob(true);
    setJobData(data);
  };

  const closeViewData = () => {
    setJobData({});
    setIsViewJob(false);
  };

  return (
    <div className='talent-job__main'>
      <ViewJob
        isViewJob={isViewJob}
        data={jobData}
        closeModal={closeViewData}
      />

      <div className='talent-job__container'>
        <div className='talent-job__page'>
          <h2 className='talent-job__page__header'>Applied for a Job?</h2>
          <p className='talent-job__page__details'>View the jobs you got below and shine!</p>
        </div>

        <Tabs
          className='talent-job__tab mb-3'
          activeKey={tabKey}
          onSelect={(key) => setTabKey(key)}
          >
          <Tab
            eventKey='active'
            title='Active'
          >
            <div className='talent-job__table'>
              <Table
                columns={activeColumns}
                dataSource={appliedJobs}
                pagination={
                  {
                    hideOnSinglePage: true
                  }
                }
              />

              <Pagination defaultCurrent={1} total={50} className='talent-job__table__pagination' />
            </div>
          </Tab>

          <Tab
            eventKey='completed'
            title='Completed'
          >
            <div className='talent-job__table'></div>
            <Table
              columns={completedColumns}
              dataSource={completedDataSource}
              pagination={
                {
                  hideOnSinglePage: true
                }
              }
            />

            <Pagination defaultCurrent={1} total={50} className='talent-job__table__pagination' />
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}
