import { useMutation } from "react-query";
import { apiClient } from "../utils/ApiClient";
import { Endpoints } from "../utils/endpoints";

const loginEmployer = async ({ email, password }) => {
  const response = await apiClient.post(Endpoints.loginEmployer, {
    email, password
  });

  if (response.status == 'error') {
    throw new Error(response.errors);
  }
  return response;
};

export const useLoginEmployer = () => {
  return useMutation(
    loginEmployer
  )
}
