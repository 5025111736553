import React from 'react';
import { Link } from "react-router-dom";
import Typography from "../../../../../components/common/Typography";
import CustomGradientButton from "../../../../../components/common/CustomGradientButton";
import { size } from '../../../../../styles/size';
import './index.scss';


export const ResetPassword = () => {
  return (
    <div className='reset-password'>
      <div className='reset-password__container'>
        <Typography
          text='Reset Password'
          fontSize={size.size05}
          fontWeight='600'
          className='reset-password__title'
        />
        <Typography
          text='Enter a new password to proceed'
          fontSize={size.size04}
          className='reset-password__sub-title'
        />

        <form className='reset-password__form'>
          <Typography
            text='Password'
            textAlign='left'
            fontSize={'14px'}
          />
          <input
            type="password"
            className=""
            id="password"
            name="password"
            aria-describedby="Password"
            onChange={() => {}}
            required
          />

          <Typography
            text='Confirm Password'
            textAlign='left'
            fontSize={'14px'}
          />
          <input
            type="password"
            className=""
            id="confirmPassword"
            name="confirmPassword"
            aria-describedby="Confirm Password"
            onChange={() => {}}
            required
          />

          <CustomGradientButton
            text="Reset Password"
            fontColor="#fff"
            width="100%"
            borderRadius="5px"
            backgroundImage="linear-gradient(#E5AD5A, #D26E2A)"
            border="0"
            btnLooading={false}
            margin={'0 0 24px 0'}
          />
        </form>
      </div>
    </div>
  );
}
