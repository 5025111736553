import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { Table, Pagination } from 'antd';
import { Navbar } from '../common-blocks/navbar';
import { Footer } from '../common-blocks/footer';
import { Proposal } from "../jobs/blocks/proposal";
import { useGetEmployersJobApplicantsQuery } from "../../../../hooks/queries/useEmployerGetJobApplicants";
import { useEmployersGetJobDetailsQuery } from "../../../../hooks/queries/useEmployersGetJobDetailsQuery";
import { useSelectJobApplicant } from "../../../../hooks/mutations/useSelectJobApplicant";
import { useUpdateJobStatus } from "../../../../hooks/mutations/useUpdateJobStatus";
import { LoadSpinner } from "../../../../components/common/LoadSpinner";
import { enqueueSnackbar } from "notistack";
import './index.scss';

export const ViewJob = () => {

  const [isViewProposal, setIsViewProposal] = useState(false);
  const [viewingApplicant, setViewingApplicant] = useState(null);

  const { id } = useParams();

  const { data: jobApplicants, isLoading: isJobApplicantsLoading } = useGetEmployersJobApplicantsQuery(id);

  const { data: jobDetails, refetch: reFetchJobDetails, isLoading: isFetchingJobDetails } = useEmployersGetJobDetailsQuery(id);

  const { mutate: selectJobApplicationMutate, isLoading: isSelectingJobApplication } = useSelectJobApplicant();

  const { mutate: updateJobStatusMutate, isLoading: isUpdatingJobStatus } = useUpdateJobStatus();

  const isLoading = useMemo(() => {
    return isJobApplicantsLoading || isFetchingJobDetails || isSelectingJobApplication || isUpdatingJobStatus;
  }, [isJobApplicantsLoading, isFetchingJobDetails, isSelectingJobApplication, isUpdatingJobStatus])

  const dataSource = useMemo(() => {
    if (!jobApplicants || !jobApplicants.hasOwnProperty('data')) return [];

    return jobApplicants?.data?.map((applicant, index) => ({
      applicantId: applicant.id,
      jobSeekerId: applicant?.jobSeeker?.id,
      id: index + 1,
      name: `${applicant.jobSeeker.firstName} ${applicant.jobSeeker.lastName}`,
      coverLetter: applicant.coverLetter,
      key: applicant.id
    }));
  }, [jobApplicants]);

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id,'
    },
    {
      title: 'Applicants',
      dataIndex: 'name',
      key: 'name',
      className: '',
      render: (_, applicant) => (
        <>
          <Link to={`/employers/jobs/${id}/job-seeker/${applicant.jobSeekerId}`}>{applicant.name}</Link>
        </>
      )
    },
    {
      title: <p className='employers-view-job__table__action'>Actions</p>,
      dataIndex: 'view',
      key: 'view',
      className: 'employers-view-job__table__view',
      render: (_, applicant) => (
        <>
          <button className='employers-view-job-button employers-view-job__view-button' onClick={
              () => {
                setIsViewProposal(true);
                setViewingApplicant(applicant);
              }
            }
          >
            View Proposal
          </button>
          {(jobDetails) &&
            (jobDetails?.data?.jobApplicationId === applicant.applicantId) ?
              <button
                disabled={true}
                className='employers-view-job-button employers-view-job__select-button'
              >
                Selected
              </button> :
              <button
                className='employers-view-job-button employers-view-job__select-button'
                onClick={() => handleSelectJobApplication(id, applicant.applicantId)}
                disabled={jobDetails?.data?.status !== 'not-started'}
              >
                Select
              </button>}
        </>
      )
    }
  ];

  const handleSelectJobApplication =  (jobId, jobApplicationId) => {
    selectJobApplicationMutate({
      jobId, jobApplicationId
    }, {
      onSuccess: ({ message }) => {
        enqueueSnackbar(message, { variant: 'success' });
        reFetchJobDetails();
      },
      onError: ({ message }) => {
        enqueueSnackbar(message, { variant: 'error' });
      }
    });
  }

  const handleUpdateJobStatus = (status) => {
    updateJobStatusMutate({ jobId: id, status }, {
      onSuccess: ({ message }) => {
        enqueueSnackbar(message, { variant: 'success' });
        reFetchJobDetails();
      },
      onError: ({ message }) => {
        enqueueSnackbar(message, { variant: 'error' });
      }
    });
  }

  return (
    <div className='employers-view-job__main'>
      <Navbar />
      {
        isLoading && <LoadSpinner />
      }

      <Proposal
        isViewProposal={isViewProposal}
        closeModal={
          () => {
            setIsViewProposal(false);
            setViewingApplicant(null);
          }
        }
        application={viewingApplicant}
        title={jobDetails?.data?.title}
      />

      <div className='container employers-view-job__container'>
        <div className='employers-view-job__page'>
          <div>
            <h2 className='employers-view-job__page__header employers-view-job-bold'>Lead Guitarist Needed</h2>
            <p className='employers-job__page__details'>Select a job application</p>
          </div>
          {
            jobDetails?.data?.jobApplicationId && jobDetails?.data?.status === 'not-started' ?
              <button
                className='employers-view-job-button employers-view-job__select-button'
                onClick={() => handleUpdateJobStatus('in-progress')}
              >
                Start Job
              </button> :
              (jobDetails?.data?.jobApplicationId && jobDetails?.data?.status === 'in-progress') ?
                <button
                  className='employers-view-job-button employers-view-job__select-button'
                  onClick={() => handleUpdateJobStatus('completed')}
                >
                  Complete Job
                </button> :
                (jobDetails?.data?.jobApplicationId && jobDetails?.data?.status === 'completed') &&
                  <p className='employers-view-job__completed-badge'>Completed</p>
          }
        </div>

        <div className='employers-view-job__table'>
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={
              {
                hideOnSinglePage: true
              }
            }
          />

          <Pagination defaultCurrent={1} total={20} className='employers-view-job__table__pagination' />
        </div>
      </div>

      <Footer />
    </div>
  )
}
