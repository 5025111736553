export const size = {
  size00: '2px',
  size01: '4px',
  size02: '8px',
  size03: '12px',
  size04: '16px',
  size05: '24px',
  size06: '32px',
  size07: '40px',
  size08: '48px',
  size09: '56px',
  size10: '64px',
  size11: '80px',
  size12: '86px',
  size13: '92px',
  size14: '100px',

  mediaSm: '992px'
}
