import React from "react";
import { Link } from "react-router-dom";
import { FaTimesCircle, FaUserTag, FaUtensils } from "react-icons/fa";
import notificationTypes from "../../../../../utils/notificationTypes";
import moment from "moment/moment";


const Notification = ({ notification }) => {
  return (
    <li>
      {
        notification.type === notificationTypes.selectedApplicant &&
          <div className="offcanvas-notification">
            <FaUserTag size={28} className="offcanvas-notification__selected" />
            <div className="offcanvas-new-applicant">
              <span className="offcanvas-new-applicant">
                You have been selected for the job: <b>{notification?.data?.jobName}</b>
              </span>
              <br/>
              <span className="offcanvas-notification-time">{moment(notification?.createdAt).fromNow()}</span>
            </div>
          </div>
      }
      {
        notification.type === notificationTypes.jobComplete &&
        <Link to="">
          <div className="offcanvas-notification">
            <FaUtensils size={28} />
            <div className="offcanvas-new-applicant">
              <span className="offcanvas-new-applicant">
                Your payment for the job <b>{notification?.data?.jobName}</b> has been added to your account.
              </span>
              <br/>
              <span className="offcanvas-notification-time">{moment(notification?.createdAt).fromNow()}</span>
            </div>
          </div>
        </Link>
      }
    </li>
  )
}

export const Notifications = ({ notifications, isNotificationsOpen, setIsNotificationsOpen }) => {

  return (
    <div className={`offcanvas ${isNotificationsOpen && 'offcanvas-open'}`}>
      <div className='offcanvas-header'>
        <p>Notifications</p>
        <FaTimesCircle size={16} className='offcanvas-header-close' onClick={() => setIsNotificationsOpen(false)} />
      </div>
      {
        notifications && notifications.length > 0 ? notifications.map(notif => (
            <Notification key={notif.id} notification={notif} />
          )) :
          <p className="offcanvas-no-notification">You have no notifications at the moment</p>
      }
    </div>
  );
}
