import React, { useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import Typography from "../../../../../components/common/Typography";
import { LoadSpinner } from "../../../../../components/common/LoadSpinner";
import { size } from "../../../../../styles/size";
import { useLoginEmployer } from "../../../../../hooks/useLoginEmployer";


export const Login = () => {
  const [data, setData] = useState({
    email: '',
    password: ''
  });

  const { mutate: loginForm, isLoading } = useLoginEmployer();

  const history = useHistory();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((state) => ({ ...state, [name]: value }));
  }

  const handleLogin = (event) => {
    event.preventDefault();
    loginForm(data, {
      onSuccess: ({ token }) => {
        localStorage.setItem('token', token);
        enqueueSnackbar('Login Successful', { variant: 'success' });
        history.push('/employers/dashboard');
      },
      onError: ({ message }) => {
        enqueueSnackbar(message, { variant: 'error' });
      }
    });
  }

  return (
    <div className='login'>

      {
        isLoading && <LoadSpinner />
      }

      <div className='login__container'>
        <Typography
          text='Login'
          fontSize={size.size05}
          fontWeight='600'
          className='login__title'
        />
        <Typography
          text='Enter login details to proceed'
          fontSize={size.size04}
          className='login__sub-title'
        />

        <form className='login__form' onSubmit={handleLogin}>
          <Typography
            text='Email Address'
            textAlign='left'
            fontSize={'14px'}
          />
          <input
            type="email"
            className=""
            id="email"
            name="email"
            aria-describedby="Email"
            onChange={handleInputChange}
            required
          />

          <Typography
            text='Password'
            textAlign='left'
            fontSize={'14px'}
          />
          <input
            type="password"
            className=""
            id="password"
            name="password"
            aria-describedby="Password"
            onChange={handleInputChange}
            required
          />

          <Link to='/forgot-password' className='login__forgot-password'>Forgot Password?</Link>

          <input className='submit-button' type='submit' value='Login' disabled={isLoading} />
        </form>

        <Typography
          text={
            <>
              Don't have an account?
              <Link to='/employers/signup' className='login__create-password'>Create Account</Link>
            </>
          }
        />
      </div>
    </div>
  )
}
