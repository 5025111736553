import React, { useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import Typography from "../../../../../components/common/Typography";
import { LoadSpinner } from "../../../../../components/common/LoadSpinner";
import { useSignupEmployer } from "../../../../../hooks/useSignupEmployer";
import { size } from "../../../../../styles/size";
import './index.scss';


export const Signup = () => {
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: ''
  });

  const history = useHistory();

  const { mutate: submitForm, isLoading } = useSignupEmployer();

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setData((state) => ({ ...state, [name]: value }));

    if (name == 'confirmPassword') {
      if (value !== data.password) {
        event.target.setCustomValidity("Passwords do not match");
      } else {
        event.target.setCustomValidity("");
      }
    }
  }

  const onSignupSubmit = (event) => {
    event.preventDefault();
    submitForm(data, {
      onSuccess: ({ token }) => {
        localStorage.setItem('token', token);
        enqueueSnackbar('Account Created Successfully', { variant: 'success' });
        history.push('/employers/dashboard');
      },
      onError: ({ message }) => {
        enqueueSnackbar(message, { variant: 'error' });
      }
    });
  }

  return (
    <div className='signup'>

      {
        isLoading && <LoadSpinner />
      }

      <div className='signup__container'>
        <Typography
          text='Create Account'
          fontSize={size.size05}
          fontWeight='600'
          className='signup__title'
        />
        <Typography
          text='Enter details to create an account'
          fontSize={size.size04}
          className='signup__sub-title'
        />

        <form className='signup__form' onSubmit={onSignupSubmit}>
          <div className='signup__form__row'>
            <div className='signup__form__row-item'>
              <Typography
                text='First Name'
                textAlign='left'
                fontSize={'14px'}
              />
              <input
                type="text"
                className=""
                id="firstName"
                name="firstName"
                aria-describedby="firstName"
                onChange={handleInputChange}
                required
              />
            </div>
            <div className='signup__form__row-item'>
              <Typography
                text='Last name'
                textAlign='left'
                fontSize={'14px'}
              />
              <input
                type="text"
                className=""
                id="lastName"
                name="lastName"
                aria-describedby="lastName"
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <div className='signup__form__row'>
            <div className='signup__form__row-item'>
              <Typography
                text='Email'
                textAlign='left'
                fontSize={'14px'}
              />
              <input
                type="email"
                className=""
                id="email"
                name="email"
                aria-describedby="email"
                onChange={handleInputChange}
                required
              />
            </div>
            <div className='signup__form__row-item'>
              <Typography
                text='Phone Number'
                textAlign='left'
                fontSize={'14px'}
              />
              <input
                type="text"
                className=""
                id="phoneNumber"
                name="phoneNumber"
                aria-describedby="phoneNumber"
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <div className='signup__form__row'>
            <div className='signup__form__row-item'>
              <Typography
                text='Password'
                textAlign='left'
                fontSize={'14px'}
              />
              <input
                type="password"
                className=""
                id="password"
                name="password"
                aria-describedby="password"
                onChange={handleInputChange}
                required
              />
            </div>
            <div className='signup__form__row-item'>
              <Typography
                text='Confirm Password'
                textAlign='left'
                fontSize={'14px'}
              />
              <input
                type="password"
                className=""
                id="confirmPassword"
                name="confirmPassword"
                aria-describedby="confirm password"
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          {/*<div className='signup__form__row'>*/}
          {/*  <div className='signup__form__row-item'>*/}
          {/*    <Typography*/}
          {/*      text='Nationality'*/}
          {/*      textAlign='left'*/}
          {/*      fontSize={'14px'}*/}
          {/*    />*/}
          {/*    <input*/}
          {/*      type="text"*/}
          {/*      className=""*/}
          {/*      id="nationality"*/}
          {/*      name="nationality"*/}
          {/*      aria-describedby="nationality"*/}
          {/*      onChange={() => {}}*/}
          {/*      required*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  <div className='signup__form__row-item'>*/}
          {/*    <Typography*/}
          {/*      text='State'*/}
          {/*      textAlign='left'*/}
          {/*      fontSize={'14px'}*/}
          {/*    />*/}
          {/*    <input*/}
          {/*      type="text"*/}
          {/*      className=""*/}
          {/*      id="state"*/}
          {/*      name="state"*/}
          {/*      aria-describedby="state"*/}
          {/*      onChange={() => {}}*/}
          {/*      required*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<CustomGradientButton*/}
          {/*  text="Next"*/}
          {/*  fontColor="#fff"*/}
          {/*  width="100%"*/}
          {/*  borderRadius="5px"*/}
          {/*  backgroundImage="linear-gradient(#E5AD5A, #D26E2A)"*/}
          {/*  border="0"*/}
          {/*  btnLooading={false}*/}
          {/*  margin={'0 0 24px 0'}*/}
          {/*  onClick={onSignupSubmit}*/}
          {/*/>*/}

          <input className='submit-button' type="submit" value="Create Account"/>
        </form>

        <Typography
          text={
            <>
              Already have an account?
              <Link to='/employers/login' className='signup__login'>Login</Link>
            </>
          }
        />
      </div>
    </div>
  )
}
