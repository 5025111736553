import { useMutation } from "react-query";
import { Endpoints } from "../../utils/endpoints";
import { apiClient } from "../../utils/ApiClient";


const updateJobStatus = async ({ jobId, status }) => {
  const response = await apiClient.put(Endpoints.employerUpdateJobStatus(jobId), { status });

  if (response.status === 'error') throw new Error(response.errors);

  return response;
}

export const useUpdateJobStatus = () => {
  return useMutation(
    updateJobStatus
  );
}
