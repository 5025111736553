import { useQuery } from "react-query";
import { QueryKeys } from "../../utils/queryKeys";
import { apiClient } from "../../utils/ApiClient";
import { Endpoints } from "../../utils/endpoints";


const getAllTalentsJobs = async (limit, categoryId) => {
  return await apiClient.get(Endpoints.talentsGetAllJobs(limit, categoryId));
}

export const useTalentsGetAllJobs = (categoryId = null, limit = 10) => {
  return useQuery({
    queryKey: QueryKeys.talentsGetAllJobs(categoryId),
    queryFn: () => getAllTalentsJobs(limit, categoryId),
    refetchOnMount: false,
    refetchOnWindowFocus: false
  })
}
