import React, { useState, useEffect, useMemo } from "react";
import Modal from 'react-bootstrap/Modal';
import { LoadSpinner } from "../common/LoadSpinner";
import { useEmployersGetJobDetailsQuery } from "../../hooks/queries/useEmployersGetJobDetailsQuery";
import { useCategoryQuery } from "../../hooks/useCategoryQuery";


export const JobDetailsModal = ({ isCreateJobOpen, setIsCreateJobOpen, id }) => {
  const [data, setData] = useState({
    title: '',
    description: '',
    categoryId: '',
    amount: null,
    location: '',
    date: null,
    time: null
  });
  const { data: categoryData } = useCategoryQuery();
  const closeModal = () => setIsCreateJobOpen(false);
  const { data: jobDetailsData, isLoading} = useEmployersGetJobDetailsQuery(id);

  const categoryOption = useMemo(() => {
    if (
      !categoryData || !categoryData.hasOwnProperty('data')
      || !jobDetailsData || !jobDetailsData.hasOwnProperty('data')
    ) return <option disabled selected>-- select an option --</option>;

    const category = categoryData?.data?.find((option) => option.id === jobDetailsData.data.categoryId)
    return <option selected disabled>{category?.name}</option>
  }, [categoryData, jobDetailsData]);

  useEffect(() => {
    if (!jobDetailsData) return;

    setData({
      ...jobDetailsData.data,
      date: jobDetailsData.data.date.substring(0, 10)
    });
  }, [jobDetailsData]);

  return (
    <Modal show={isCreateJobOpen} onHide={closeModal} size='lg'>
      <Modal.Body>
        <button className="btn close" onClick={closeModal}>
          <span aria-hidden="true">×</span>
        </button>

        {
          isLoading && <LoadSpinner />
        }

        <h2 className="text-center text-dark create-job__header">Job Details</h2>

        <form
          className="row g-3 needs-validation"
        >
          <div className="col-md-12">
            <label htmlFor="title" className="form-label">
              Who are you looking for?
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              name="title"
              required
              onChange={() => {}}
              value={data.title}
              disabled
            />
          </div>

          <div className="col-lg-6">
            <label htmlFor="budgetTo" className="form-label">
              Amount (NGN)
            </label>
            <input
              type="number"
              className="form-control"
              id="amount"
              name="amount"
              onChange={() => {}}
              value={data.amount}
              required
              disabled
            />
          </div>

          <div className="col-lg-6">
            <label htmlFor="categoryId" className="form-label">
              Category
            </label>
            <select
              className="form-select"
              id="categoryId"
              name="categoryId"
              required
              onChange={() => {}}
              disabled
            >
              {categoryOption}
            </select>
          </div>

          <div className="col-lg-6">
            <label htmlFor="lga" className="form-label">
              Location
            </label>
            <input
              required
              type='text'
              className={'form-control'}
              id='location'
              name='location'
              onChange={() => {}}
              value={data.location}
              autoComplete={'off'}
              disabled
            />
          </div>

          <div className="col-lg-6">
            <label htmlFor="date" className="form-label">
              Date
            </label>
            <input
              required
              type="date"
              className="form-control text-uppercase"
              id="date"
              name="date"
              onChange={() => {}}
              value={data.date}
              disabled
            />
          </div>

          <div className="col-lg-6">
            <label htmlFor="time" className="form-label">
              Time
            </label>
            <input
              type="time"
              className="form-control"
              id="time"
              name="time"
              onChange={() => {}}
              value={data.time}
              required
              disabled
            />
          </div>

          <div className="col-12">
            <label htmlFor="description" className="form-label">
              Job Description
            </label>

            <textarea
              className="form-control"
              rows="4"
              id="description"
              name="description"
              required
              onChange={() => {}}
              value={data.description}
              disabled
            ></textarea>
          </div>

          {/*<div className="col-lg-12 col-lg-12">*/}
          {/*  <input className='submit-button' type='submit' value='Post Job' disabled />*/}
          {/*</div>*/}
        </form>
      </Modal.Body>
    </Modal>
  );
}
