export const QueryKeys = {
  // employers
  employersGetAllJobs: (status) => `${status}-employerGetAllJobs`,
  employersGetJobDetails: (jobId) => ['employersGetJobDetails', jobId],
  employersGetJobApplicants: (jobId) => ['employersGetJobApplicants', jobId],
  employerUserDetails: 'employerUserDetails',
  employerTransactions: 'employerTransactions',
  employerJobSeeker: (jobSeekerId) => ['employerJobSeeker', jobSeekerId],

  // talents
  talentsGetAllJobs: (categoryId) => `talentsGetAllJobs-${categoryId}`,
  jobSeekersGetMyJobs: 'jobSeekersGetMyJobs',
  jobSeekersUserDetails: 'jobSeekerUserDetails',
  jobSeekerTransaction: 'jobSeekerTransaction',
  jobSeekerMedia: 'jobSeekerMedia',

  // common
  getCategories: 'getCategories',
  getNotifications: 'getNotifications',
};
