import React, { useState, useMemo } from "react";
import { Slider, Divider } from "antd";
import Cropper from 'react-easy-crop';
import { enqueueSnackbar } from "notistack";
import Modal from 'react-bootstrap/Modal';
import Typography from "./Typography";
import getCroppedImg from "../../utils/cropImage";
import axios from "axios";


export const CropSaveImage = ({ showModal, closeModal, photoURL, setFormPhotoURL, setFile }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const uploadToCloudinary = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'development');

    const response = await axios.post(
      'https://api.cloudinary.com/v1_1/showkonnect/image/upload',
      formData
    );

    if (response.status === 200) return {
      status: 'success',
      secureUrl: response.data.secure_url
    }
    else {
      enqueueSnackbar('Error: Please try again later!', { variant: 'error' });
      return {
        status: 'fail',
        secureUrl: null
      }
    }
  }

  const cropSaveImage = async () => {
    try {
      setIsUploading(true);
      const { file } = await getCroppedImg(
        photoURL,
        croppedAreaPixels,
        rotation
      );

      const { status, secureUrl } = await uploadToCloudinary(file);
      if (status === 'success') {
        setFormPhotoURL(secureUrl);
        setFile(file);
        closeModal();
      }
      setIsUploading(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      console.log(error);
      setIsUploading(false);
    }
  };

  const isLoading = useMemo (() => {
    return isUploading;
  }, [isUploading]);

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Crop</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='container'>
          <div
            style={{ paddingLeft: '30px', paddingRight: '30px', display: 'flex', flexDirection: 'column', height: '35dvh' }}
          >
            <Cropper
              image={photoURL}
              crop={crop}
              zoom={zoom}
              rotation={rotation}
              aspect={1}
              onZoomChange={setZoom}
              onRotationChange={setRotation}
              onCropChange={setCrop}
              onCropComplete={cropComplete}
            />
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div
          style={{ width: '100%' }}
        >
          <Divider />

          <Slider
            valueLabelDisplay="auto"
            min={1}
            max={3}
            step={0.1}
            value={zoom}
            onChange={( zoom) => setZoom(zoom)}
          />

          <Typography
            text={<span>Zoom: {zoomPercent(zoom)}</span>}
            textAlign='center'
            fontSize={'14px'}
            className='employers-profile__details__form__group__text'
          />

          <Divider />

          <Slider
            valueLabelDisplay="auto"
            min={0}
            max={360}
            value={rotation}
            onChange={( rotation) => setRotation(rotation)}
          />

          <Typography
            text={<span>Rotation: {rotation}</span>}
            textAlign='center'
            fontSize={'14px'}
            className='employers-profile__details__form__group__text'
          />

          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'right' }}
          >
            <button className='btn employers-profile__details__form__cancel-button' style={{ marginRight: '10px' }} onClick={closeModal} disabled={isLoading}>
              Cancel
            </button>
            <button className='employers-profile__details__form__button' onClick={cropSaveImage} disabled={isLoading}>
              Save
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

const zoomPercent = (value) => {
  return `${Math.round(value * 100)}%`;
};
