import { FaTimesCircle, FaUserTag } from "react-icons/fa";
import notificationTypes from "../../../../../../../utils/notificationTypes";
import { Link } from "react-router-dom";
import React from "react";
import moment from "moment";

const Notification = ({ notification }) => {
  return (
    <li key={notification.id}>
      {
        notification.type === notificationTypes.newJobApplication &&
        <Link to={`/employers/jobs/${notification?.data?.jobId}`}>
          <div className="offcanvas-notification">
            <FaUserTag size={28} className="offcanvas-notification__new-job" />
            <div className="offcanvas-new-applicant">
              <span>
                You have a new job applicant for <b>{notification?.data?.jobName} job posting.</b>
              </span>
              <br/>
              <span className="offcanvas-notification-time">{moment(notification?.createdAt).fromNow()}</span>
            </div>
          </div>
        </Link>
      }
    </li>
  );
};


export const Notifications = ({ isNotificationsOpen, notifications, setIsNotificationsOpen }) => {

  return(
    <div className={`offcanvas ${isNotificationsOpen && 'offcanvas-open'}`}>
      <div className='offcanvas-header'>
        <p>Notifications</p>
        <FaTimesCircle size={16} className='offcanvas-header-close' onClick={() => setIsNotificationsOpen(false)} />
      </div>

      <ul className='offcanvas-list'>
        {
          notifications && notifications.length > 0 ? notifications.map(notification => (
              <Notification
                notification={notification}
                key={notification.id}
              />
            )) :
            <p className="offcanvas-no-notification">You have no notifications at the moment</p>
        }
      </ul>
    </div>
  );
}
