import React, { useState } from 'react';
import { Modal } from 'antd';
import { EmployerLogin } from "./blocks/employerLogin";
import { EntertainerLogin } from "./blocks/entertainerLogin";
import { Navbar } from "../../../../components/v2/navbar";
import './index.scss';

export const NewLogin = () => {
  const [isEmployerModalOpen, setIsEmployerModalOpen] = useState(false);
  const [isEntertainerModalOpen, setIsEntertainerModalOpen] = useState(false);

  const handleEmployerOpenModal = () => setIsEmployerModalOpen(true);
  const handleEmployerCloseModal = () => setIsEmployerModalOpen(false);
  const handleEntertainerOpenModal = () => setIsEntertainerModalOpen(true);
  const handleEntertainerCloseModal = () => setIsEntertainerModalOpen(false);
  return (
    <>
      <Navbar />
      <div className="new-login">
        <div className="new-login__container">
          <Modal
            title="Employer Login"
            open={isEmployerModalOpen}
            onOk={handleEmployerCloseModal}
            onCancel={handleEmployerCloseModal}
            footer={null}
            centered
          >
            <EmployerLogin />
          </Modal>

          <Modal
            title="Entertainer Login"
            open={isEntertainerModalOpen}
            onOk={handleEntertainerCloseModal}
            onCancel={handleEntertainerCloseModal}
            footer={null}
            centered
          >
            <EntertainerLogin />
          </Modal>

          <div className="new-login__card new-login__employer" onClick={handleEmployerOpenModal} />
          <div className="new-login__card new-login__entertainer" onClick={handleEntertainerOpenModal} />
        </div>
      </div>
    </>
  )
}
