import { useQuery } from "react-query";
import { QueryKeys } from '../utils/queryKeys';
import { apiClient } from "../utils/ApiClient";
import { Endpoints } from "../utils/endpoints";

const getAllEmployerJobs = async (status) => {
  const response = await apiClient.get(Endpoints.employerJob(status));
  return response;
};

export const useEmployerGetAllJobs = (status) => {
  return useQuery({
    queryKey: QueryKeys.employersGetAllJobs(status),
    queryFn: () => getAllEmployerJobs(status),
    refetchOnMount: false,
    refetchOnWindowFocus: false
  })
}
