import { useMutation } from "react-query";
import { apiClient } from "../../utils/ApiClient";
import { Endpoints } from "../../utils/endpoints";


const deleteYoutubeMedia = async ({ id }) => {
  const response = await apiClient.delete(Endpoints.jobSeekerDeleteMedia(id));

  if (response.status === 'error') {
    throw new Error(response.error);
  }

  return response;
}
export const useJobSeekerDeleteYoutubeMedia = () => {
  return useMutation(
    deleteYoutubeMedia
  );
};
