import React, { useState, useMemo } from 'react';
import moment from 'moment';
import { LoadSpinner } from "../../../../components/common/LoadSpinner";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useTalentsGetAllJobs } from "../../../../hooks/queries/useTalentsGetAllJobs";
import { useCategoryQuery } from "../../../../hooks/useCategoryQuery";
import { ApplyModal } from "./blocks/applyModal";
import { JobCard } from "./blocks/job-card";
import { useApplyToJob } from "../../../../hooks/mutations/useApplyToJob";
import { enqueueSnackbar } from "notistack";
import './index.scss';
import {useJobSeekerUserDetails} from "../../../../hooks/queries/useJobSeekerUserDetails";

export const Dashboard = () => {
  const [tabKey, setTabKey] = useState('bestMatches');
  const [isViewApplyModal, setIsViewApplyModal] = useState(false);
  const [jobToApply, setJobToApply] = useState(null);
  const [jobToApplyCoverLetter, setJobToApplyCoverLetter] = useState(`I'm interested in this opportunity...`);

  const { mutate: applyToJob } = useApplyToJob();
  const { data: userDetails } = useJobSeekerUserDetails();
  const { data: allJobs, isLoading: isAllJobsLoading } = useTalentsGetAllJobs();
  const { data: categoryJobs, isLoading: isCategoryJobsLoading } = useTalentsGetAllJobs(userDetails?.data?.categoryId);
  const { data: categoryData } = useCategoryQuery();


  const allJobsData = useMemo(() => {
    if (!allJobs || !allJobs.hasOwnProperty('data') || !allJobs?.data.hasOwnProperty('rows') || !categoryData || !categoryData.hasOwnProperty('data')) return [];

    return allJobs.data.rows.map((jobData) => {
      const timePosted = moment(jobData.createdAt, 'YYYY-MM-DD').fromNow();
      const category = categoryData?.data?.find((option) => option.id === jobData.categoryId)

      return {
        id: jobData.id,
        jobTitle: jobData.title,
        location: jobData.location,
        date: jobData.date.substring(0, 10),
        time: `Posted ${timePosted}`,
        category: category?.name
      }
    })
  }, [allJobs, categoryData]);

  const categoryJobsData = useMemo(() => {
    if (!categoryJobs || !categoryJobs.hasOwnProperty('data') || !categoryJobs?.data.hasOwnProperty('rows') || !categoryData || !categoryData.hasOwnProperty('data')) return [];

    return categoryJobs.data.rows.map((jobData) => {
      const timePosted = moment(jobData.createdAt, 'YYYY-MM-DD').fromNow();
      const category = categoryData?.data?.find((option) => option.id === jobData.categoryId)

      return {
        id: jobData.id,
        jobTitle: jobData.title,
        location: jobData.location,
        date: jobData.date.substring(0, 10),
        time: `Posted ${timePosted}`,
        category: category?.name
      }
    })
  }, [categoryJobs, categoryData]);

  const isLoading = useMemo(() => {
    return isAllJobsLoading || isCategoryJobsLoading;
  }, [isAllJobsLoading, isCategoryJobsLoading]);

  const onJobApply = (job) => {
    setJobToApply(job);
    setIsViewApplyModal(true);
  }

  const onCloseViewApplyModal = () => {
    setIsViewApplyModal(false);
    setJobToApply(null);
    setJobToApplyCoverLetter(`I'm interested in this opportunity...`);
  }

  const handleJobApply = () => {
    applyToJob({
      jobId: jobToApply.id,
      coverLetter: jobToApplyCoverLetter
    },
      {
        onSuccess: (response) => {
          enqueueSnackbar("Successfully applied to job", { variant: "success" });
          onCloseViewApplyModal();
        },
        onError: ({ message }) => {
          enqueueSnackbar(message, { variant: "error" })
          onCloseViewApplyModal();
        }
    });
  }

  return (
    <div className='talent-dashboard__main'>
      { isLoading && <LoadSpinner /> }

      <ApplyModal
        isViewApplyModal={isViewApplyModal}
        closeViewApplyModal={onCloseViewApplyModal}
        job={jobToApply}
        setJobToApplyCoverLetter={setJobToApplyCoverLetter}
        handleJobApply={handleJobApply}
      />

      <div className='talent-dashboard__container'>
        <div className=''>
          <div className='talent-dashboard__page'>
            <h2 className='talent-dashboard__page__header'>Job Openings</h2>
            <p className='talent-dashboard__page__details'>Find jobs that best match your profile.</p>
          </div>

          <Tabs
            id='talent-dashboard__tab'
            activeKey={tabKey}
            onSelect={(key) => setTabKey(key)}
            className='talent-dashboard__tab'
          >
            <Tab eventKey='bestMatches' title='Best Matches'>
              {
                categoryJobsData?.length === 0 && <p className="talent-dashboard__no-data">
                  There are currently no jobs that match your category.<br/>You can check other categories.
                </p>
              }
              {
                categoryJobsData?.map((job) => (
                  <JobCard key={job.id} job={job} onApply={onJobApply} />
                ))
              }
            </Tab>

            <Tab eventKey='otherCategories' title='All Categories'>
              {
                allJobsData?.length === 0 && <p className="talent-dashboard__no-data">No Data</p>
              }
              {
                allJobsData?.map((job) => (
                  <JobCard key={job.id} job={job} onApply={onJobApply} />
                ))
              }
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  )
}
