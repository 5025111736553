import React, { useState } from "react";
import { LoadSpinner } from "../../../../../components/common/LoadSpinner";
import { useLoginJobSeeker } from "../../../../../hooks/mutations/useLoginJobSeeker";
import { Link, useHistory } from "react-router-dom";
import Typography from "../../../../../components/common/Typography";
import { enqueueSnackbar } from "notistack";
import './index.scss';


export const EntertainerLogin = () => {
  const [data, setData] = useState({
    email: '',
    password: ''
  });
  const { mutate: loginForm, isLoading } = useLoginJobSeeker();
  const history = useHistory();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((state) => ({ ...state, [name]: value }));
  }

  const handleLogin = (event) => {
    event.preventDefault();

    loginForm(data, {
      onSuccess: ({ token }) => {
        localStorage.setItem('token', token);
        enqueueSnackbar('Login Successful', { variant: 'success' });
        history.push('/dashboard');
      },
      onError: ({ message }) => {
        enqueueSnackbar(message, { variant: "error" });
      }
    });
  }

  return(
    <>
      { isLoading &&
        <LoadSpinner />
      }

      <form className='entertainer-login__form' onSubmit={handleLogin}>
        <Typography
          text='Email Address'
          textAlign='left'
          fontSize={'12px'}
        />
        <input
          type="email"
          className="entertainer-login__form-input"
          id="email"
          name="email"
          aria-describedby="Email"
          onChange={handleInputChange}
          value={data.email}
          required
        />

        <Typography
          text='Password'
          textAlign='left'
          fontSize={'12px'}
        />
        <input
          type="password"
          className="entertainer-login__form-input"
          id="password"
          name="password"
          aria-describedby="Password"
          onChange={handleInputChange}
          value={data.password}
          required
        />

        <Link to='/forgot-password' className='entertainer-login__forgot-password'>Forgot Password?</Link>

        <input className='entertainer-login__submit-button' type='submit' value='Login' disabled={isLoading} />
      </form>

      <Typography
        text={
          <>
            Don't have an account?
            <Link to='/new-signup' className='entertainer-login__create-password'>Create Account</Link>
          </>
        }
        fontSize={'14px'}
      />
    </>
  );
}
