import React, { useState } from 'react';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useLocation } from 'react-router-dom';
import { Navbar } from '../common-blocks/navbar';
import { Footer } from '../common-blocks/footer';
import './index.scss';
import { ActiveJobs } from "./blocks/ActiveJobs";
import { PaymentPendingJobs } from "./blocks/PaymentPendingJobs";
import { CompletedJobs } from "./blocks/CompletedJobs";

export const Jobs = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const status = searchParams.get('status');

  const [tabKey, setTabKey] = useState(status ? status : 'active');

  return (
    <div className='employers-job__main'>
      <Navbar />

      <div className='container employers-job__container'>
        <div className='employers-job__page'>
          <h2 className='employers-job__page__header employers-job-bold'>My Jobs</h2>
          <p className='employers-job__page__details'>View your Job History</p>
        </div>

        <Tabs
          id='employers-job__tab'
          className='employers-job__tab mb-3'
          activeKey={tabKey}
          onSelect={(key) => setTabKey(key)}
        >
          <Tab
            eventKey='active'
            title='Active'
          >
            <ActiveJobs />
          </Tab>

          <Tab
            eventKey='paymentPending'
            title='Payment Pending'
          >
            <PaymentPendingJobs />
          </Tab>

          <Tab
            eventKey='completed'
            title='Completed'
          >
            <CompletedJobs />
          </Tab>
        </Tabs>

      </div>

      <Footer />
    </div>
  )
}
