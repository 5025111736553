import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQueryClient } from "react-query";
import Typography from "../../components/common/Typography";
import CustomButton from "../../components/common/CustomButton";
import { Navbar } from "../../components/v2/navbar";
import Footer from "../../components/layout/Footer";

import { RecentJobs } from "./blocks/RecentJobs";

import SectionThreeImg from './images/section_three.png';
import SectionFourImg from './images/section_four_image.png';
import SectionSixImg from './images/section_six_image.png';
import SectionSevenImg from './images/section_seven_image.png';

import { color } from "../../styles/color";
import { size } from "../../styles/size";
import './index.scss';

export const Landing = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.resetQueries();
  }, []);

  return (
    <>
      <Navbar/>

      <div className="section-one">
        <div className="container">
          <Typography
            text={
              <span>Hire talented <span className='primary-color'>Musicians</span> and <span className='primary-color'>Entertainers</span> <br/>for your events</span>
          }
            color={color.white}
            fontSize={size.size09}
            fontWeight="600"
            lineHeight="1em"
          />

          <Typography
            text='Showkonnect is the platform to find and hire vetted musicians and entertainers near you.'
            color={color.white}
            fontSize={size.size04}
            fontWeight='400'
            />
        </div>
      </div>

      {/*<div className='section-two'>*/}
      {/*  <div className='container flex-column'>*/}
      {/*    <Typography*/}
      {/*      text='Recent Jobs'*/}
      {/*      color={color.black}*/}
      {/*      fontSize={size.size07}*/}
      {/*      fontWeight='400'*/}
      {/*    />*/}

          {/*<RecentJobs />*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div className='section-three container'>
        <div className='row'>
          <div className='col-md-6 col-sm-12 flex-column'>
            <Typography
              text='Dear Employer,'
              color={color.black}
              fontSize={size.size06}
              fontWeight="600"
              lineHeight="2em"
            />

            <Typography
              text={<>
                Booking musicians without headache <br /><br />
                Finding musicians for your event can be a pain. Showkonnect offers event planners an online registry of
                talented and vetted musicians sorted by skill, experience and location.
                <br /> <br />Post a job today and book your
                musicians ahead of schedule.
              </>}
              color={color.black}
              fontSize={size.size04}
              fontWeight="200"
              textAlign="left"
            />

            <Link to="/new-login">
              <CustomButton
                text="Post a Job"
                height="46px"
                width="215px"
                borderRadius={size.size03}
                fontSize={size.size03}
                fontWeight="500"
                fontColor={color.white}
                backgroundColor={color.primary}
              />
            </Link>
          </div>
          <div className='col-md-6 col-sm-12 section-image'>
            <img src={SectionThreeImg} alt="Section Three Image" className='img-fluid' />
          </div>
        </div>
      </div>

      <div className='section-four'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 col-sm-12'>
              <img src={SectionFourImg} alt="Section Four Image" className='img-fluid' />
            </div>
            <div className='col-md-6 col-sm-12 flex-column'>
              <Typography
                text='Dear Entertainer,'
                color={color.black}
                fontSize={size.size06}
                fontWeight="600"
                lineHeight="2em"
              />

              <Typography
                text={<>
                  Find meaningful gigs near you <br /><br />
                  Join thousands of musicians and entertainers on Showkonnect who are leveraging the platform to
                  secure paying jobs every week. <br /><br />
                  Create your profile, add your skillset, and start getting seen by event planners in minutes.
                </>}
                color={color.black}
                fontSize={size.size04}
                fontWeight="200"
                textAlign="left"
              />

              <Link to='/new-signup'>
                <CustomButton
                  text="Sign up for free"
                  height="46px"
                  width="215px"
                  borderRadius={size.size03}
                  fontSize={size.size03}
                  fontWeight="500"
                  fontColor={color.white}
                  backgroundColor={color.secondary}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className='section-five'>
        <div className='container'>
          <div className="row justify-content-center header">
            <Typography
              text="How it Works"
              color={color.black}
              fontSize={size.size06}
              fontWeight="600"
              fontStyle="normal"
              lineHeight="1em"
              margin={`${size.size05} 0`}
            />
            <Typography
              text={<span>Finding and hiring an entertainer on SHOWKONNECT is easier than asking someone else for a<br/>referral. Find what you need in minutes, not days.</span>}
              color={color.black}
              fontSize={size.size04}
              fontWeight="normal"
              fontStyle="normal"
            />
          </div>

          <div className="row content" >
            <div className="col-sm-12 col-lg-4 content-details">
              <span className="numberBox">1</span>
              <Typography
                text="Create Account"
                color="#101214"
                fontSize="24px"
                fontWeight="600"
                fontStyle="normal"
                lineHeight="30px"
                margin="0 0 2% 0"
              />
              <Typography
                text="Creating a Showkonnect account is free and quick. Set up your profile in minutes."
                color="#101214"
                fontSize="18px"
                fontWeight="normal"
                fontStyle="normal"
                lineHeight="30px"
                margin="0 0 10% 0"
              />
            </div>
            <div className="col-sm-12 col-lg-4 content-details">
              <span className="numberBox">2</span>
              <Typography
                text="Apply for Jobs"
                color="#101214"
                fontSize="24px"
                fontWeight="600"
                fontStyle="normal"
                lineHeight="30px"
                margin="0 0 2% 0"
              />
              <Typography
                text="After setting up your profile, you can immediately apply for jobs specific to your skillset."
                color="#101214"
                fontSize="18px"
                fontWeight="normal"
                fontStyle="normal"
                lineHeight="30px"
                margin="0 0 10% 0"
              />
            </div>
            <div className="col-sm-12 col-lg-4 content-details">
              <span className="numberBox">3</span>
              <Typography
                text="Get Paid"
                color="#101214"
                fontSize="24px"
                fontWeight="600"
                fontStyle="normal"
                lineHeight="30px"
                margin="0 0 2% 0"
              />
              <Typography
                text="Complete jobs and get paid for your work. All payments are protected by Escrow."
                color="#101214"
                fontSize="18px"
                fontWeight="normal"
                fontStyle="normal"
                lineHeight="30px"
                margin="0 0 10% 0"
              />
            </div>
          </div>
        </div>
      </div>

      <div className='section-six'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 col-sm-12 flex-column'>
              <Typography
                text='Get Paid with Escrow'
                color={color.black}
                fontSize={size.size06}
                fontWeight="600"
                lineHeight="2em"
              />

              <Typography
                text={<>Payments on SHOWKONNECT are secured via our Escrow payment system. Entertainers can focus on creating value without having to worry about getting paid for their work.<br/><br/>When a client posts a job on the platform, the agreed payment is immediately put in Escrow when an entertainer is matched with the job.<br/><br/>At the completion of every job, the client will, upon satisfaction with the work done, mark the job as done. The money in Escrow will then be released into the Entertainer's account.</>}
                color={color.black}
                fontSize={size.size04}
                fontWeight="200"
                textAlign="left"
              />
            </div>
            <div className='col-md-6 col-sm-12 section-image'>
              <img src={SectionSixImg} alt="Section Six Image" className='img-fluid' />
            </div>
          </div>
        </div>
      </div>

      <div className='section-seven container'>
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <img src={SectionSevenImg} alt="Section Seven Image" className='img-fluid' />
          </div>
          <div className='col-md-6 col-sm-12 flex-column'
            style={{
             display: "flex",
             alignItems: "flex-start",
             flexDirection: "column",
             justifyContent: "center",
            }}
          >
            <Typography
              text='Plan — or find — a gig easily with Showkonnect'
              color={color.black}
              fontSize={size.size06}
              fontWeight="600"
              lineHeight="35px"
              textAlign='left'
            />

            <div
              className="mt-3"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                backgroundColor: "#EFFAFB",
                padding: "3% 3% 0 3%",
              }}
            >
              <Typography
                text="Putting a band together"
                color="#101214"
                fontSize="24px"
                fontWeight="600"
                fontStyle="normal"
                lineHeight="30px"
                textAlign="left"
              />
              <Typography
                text="Whether you're putting a band together or looking for a solo entertainer for your upcoming party or event, leverage thousands of talents on Showkonnect to find who you need."
                color="#101214"
                fontSize="18px"
                fontWeight="normal"
                fontStyle="normal"
                lineHeight="30px"
                textAlign="left"
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                padding: "3% 3% 0 3%",
              }}
            >
              <Typography
                text="Put your best foot forward"
                color="#101214"
                fontSize="24px"
                fontWeight="600"
                fontStyle="normal"
                lineHeight="30px"
                textAlign="left"
              />
              <Typography
                text="Entertainers on eFindar have portfolio pages on their profiles that allow them to showcase their best works and land jobs."
                color="#101214"
                fontSize="18px"
                fontWeight="normal"
                fontStyle="normal"
                lineHeight="30px"
                textAlign="left"
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                padding: "3% 3% 0 3%",
              }}
            >
              <Typography
                text="No logistics Nightmares"
                color="#101214"
                fontSize="24px"
                fontWeight="600"
                fontStyle="normal"
                lineHeight="30px"
                textAlign="left"
              />
              <Typography
                text="Free yourself from logistic hassles associated with paying entertainers. Our Escrow system ensures that both parties can focus on creating wonderful experiences without fussing about pay afterward."
                color="#101214"
                fontSize="18px"
                fontWeight="normal"
                fontStyle="normal"
                lineHeight="30px"
                textAlign="left"
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
