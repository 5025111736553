import { useQuery } from "react-query";
import { apiClient } from "../../utils/ApiClient";
import { Endpoints } from "../../utils/endpoints";
import { QueryKeys } from "../../utils/queryKeys";


const getEmployerJobSeeker = async (jobSeekerId) => {
  const response = await apiClient.get(Endpoints.employerJobSeeker(jobSeekerId));

  if (response.status) {}
  return response;
}

export const useEmployerJobSeeker = (jobSeekerId) => {
  return useQuery({
    queryKey: QueryKeys.employerJobSeeker(jobSeekerId),
    queryFn: () => getEmployerJobSeeker(jobSeekerId),
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });
}
