import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBell, FaUserTag, FaTimesCircle } from 'react-icons/fa';
import { useEmployerUserDetails } from "../../../../../hooks/queries/useEmployerUserDetails";
import { useNotificationQuery } from "../../../../../hooks/queries/useNotificationQuery";
import { useReadNotifications } from "../../../../../hooks/mutations/useReadNotifications";
import Logo from '../../../../../images/SHOWKONNECT-TRANS-2.png';
import { Notifications } from "./blocks/notifications";
import './index.scss';


export const Navbar = () => {
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const { data, refetch: refetchUser } = useEmployerUserDetails();
  const { data: notificationData } = useNotificationQuery();
  const { mutate: readNotifications } = useReadNotifications();

  const handleLogout = (event) => {
    localStorage.removeItem('token');
  }

  const notifications = useMemo(() => {
    if (
      !notificationData ||
      !notificationData?.hasOwnProperty('data') ||
      typeof notificationData?.data !== 'object' ||
      notificationData?.data?.length <1
    ) return []

    return notificationData?.data?.map(notif => ({
      id: notif?.id,
      type: notif?.type,
      data: notif?.data,
      createdAt: notif?.createdAt
    }));
  }, [notificationData]);

  const handleOpenNotifications = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
    readNotifications({}, {
      onSuccess: () => {
        refetchUser();
      }
    });
  }

  return (
    <nav className="navbar navbar-dark navbar-expand-lg bg-sec">
      <div className="container-fluid container">
        <Link to='/employers/dashboard' className='navbar-brand logo-font color-pri'>
          <img src={Logo} alt={'ShowKonnect Logo'} className={'img-fluid'} width={'50'} />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon navbar-light"></span>
        </button>

        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
            <li className="nav-item marg-right-2 notification position-relative">
              <button className="notification-btn" onClick={handleOpenNotifications}>
                <FaBell />
                {
                  data?.data?.hasNotification && <span className="badge">*</span>
                }
              </button>

              <Notifications
                isNotificationsOpen={isNotificationsOpen}
                notifications={notifications}
                setIsNotificationsOpen={setIsNotificationsOpen}
              />

            </li>
            <li className="nav-item marg-right-2 logout-button">
              <Link
                to="/"
                className="nav-link color-white"
                aria-current="page"
                onClick={handleLogout}
              >
                LOGOUT
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
