import React, { useMemo } from 'react';
import { Pagination, Table } from "antd";
import { useHistory } from 'react-router-dom';
import { useEmployerGetAllJobs } from "../../../../../../hooks/useEmployerGetAllJobs";
import { JobDetails } from "../JobDetails";


export const ActiveJobs = () => {
  const history = useHistory();
  const { data: activeJobs } = useEmployerGetAllJobs('not-started');
  const activeJobsDataSource = useMemo(() => {
    if (!activeJobs) return [];

    const options = [];

    activeJobs?.data?.map((job, index) => {
      options.push({
        id: index + 1,
        jobId: job.id,
        name: job.title,
        date: job.date.substring(0, 10),
        amount: job.amount
      })
    })

    return options;
  }, [activeJobs]);

  const activeColumns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, { id, name, jobId }) => (
        <div key={id}>
          <JobDetails name={name} id={jobId} />
        </div>
      )
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: '',
      dataIndex: 'view',
      key: 'view',
      render: (_, { id, jobId }) => (
        <>
          <button
            className='employers-job__tab__view'
            onClick={() => history.push(`/employers/jobs/${jobId}`)}
          >View</button>
        </>
      )
    },
  ];

  return (
    <div className='employers-job__table'>
      <Table
        columns={activeColumns}
        dataSource={activeJobsDataSource}
        pagination={
          {
            hideOnSinglePage: true
          }
        }
      />

      {/*<Pagination defaultCurrent={1} total={50} className='employers-job__table__pagination' />*/}
    </div>
  )
}
