import { useMutation } from "react-query";
import { apiClient } from "../../utils/ApiClient";
import { Endpoints } from "../../utils/endpoints";


const addYoutubeMedia = async (data) => {
  const response = await apiClient.post(Endpoints.jobSeekerMedia, { ...data });

  if (response.status === 'error') {
    throw new Error(response.errors);
  }

  return response;
};

export const useAddYoutubeMedia = () => {
  return useMutation(
    addYoutubeMedia
  )
};
