import React, { useContext } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import CustomButton from "../common/CustomButton";
import { Link } from "react-router-dom";
import { ProfileContext } from "../../context/Profile";

import Logo from "../../images/SHOWKONNECT-TRANS-2.png";

const Navbar = () => {
  const { profile, logout } = useContext(ProfileContext);

  return (
    <nav className="navbar navbar-dark navbar-expand-lg bg-sec">
      <div className="container-fluid container">
        <Link to="/" className="navbar-brand logo-font color-pri">
          <img src={Logo} alt="ShowKonnect" className="img-fluid" width="80" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon navbar-light"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarSupportedContent"
        >
          {profile.id !== undefined ? (
            <ul className="navbar-nav ml-auto mb-2 mb-lg-0 align-items-center">
              <li className="nav-item marg-right-2">
                <Link
                  to="/home"
                  className="nav-link color-white"
                  aria-current="page"
                  href="#"
                >
                  <span className="px-1">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.8334 17.5001H4.16671C3.94569 17.5001 3.73373 17.4123 3.57745 17.256C3.42117 17.0997 3.33337 16.8878 3.33337 16.6668V9.16676H1.86803C1.50242 9.16676 1.32843 8.71672 1.59897 8.47078L9.43921 1.34343C9.59263 1.20383 9.79261 1.12646 10 1.12646C10.2075 1.12646 10.4074 1.20383 10.5609 1.34343L18.4011 8.47078C18.6716 8.71672 18.4977 9.16676 18.132 9.16676H16.6667V16.6668C16.6667 16.8878 16.5789 17.0997 16.4226 17.256C16.2663 17.4123 16.0544 17.5001 15.8334 17.5001ZM10.8334 15.8334H15V7.63093L10 3.08593L5.00004 7.63093V15.8334H9.16671V10.8334H10.8334V15.8334Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  Home
                </Link>
              </li>
              {/* <li className="nav-item marg-right-2">
                <Link className="nav-link color-white" to="/messages">
                  <span className="px-1">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.49996 2.5H17.5C17.721 2.5 17.9329 2.5878 18.0892 2.74408C18.2455 2.90036 18.3333 3.11232 18.3333 3.33333V16.6667C18.3333 16.8877 18.2455 17.0996 18.0892 17.2559C17.9329 17.4122 17.721 17.5 17.5 17.5H2.49996C2.27895 17.5 2.06698 17.4122 1.9107 17.2559C1.75442 17.0996 1.66663 16.8877 1.66663 16.6667V3.33333C1.66663 3.11232 1.75442 2.90036 1.9107 2.74408C2.06698 2.5878 2.27895 2.5 2.49996 2.5ZM16.6666 6.03167L10.7221 11.3553C10.3445 11.6935 9.77354 11.6956 9.3934 11.3602L3.33329 6.01333V15.8333H16.6666V6.03167ZM3.75913 4.16667L10.0508 9.71833L16.2516 4.16667H3.75913Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  Message
                </Link>
              </li> */}
              {/*<li className="nav-item marg-right-2">*/}
              {/*  <Link*/}
              {/*    to="/media"*/}
              {/*    className="nav-link color-white"*/}
              {/*    tabIndex="-1"*/}
              {/*    aria-disabled="true"*/}
              {/*  >*/}
              {/*    <span className="px-1">*/}
              {/*      <svg*/}
              {/*        width="20"*/}
              {/*        height="20"*/}
              {/*        viewBox="0 0 20 20"*/}
              {/*        fill="none"*/}
              {/*        xmlns="http://www.w3.org/2000/svg"*/}
              {/*      >*/}
              {/*        <path*/}
              {/*          d="M4.02329 17.5L4.00663 17.5167L3.98913 17.5H2.49329C2.27397 17.4998 2.06371 17.4125 1.9087 17.2573C1.75369 17.1022 1.66663 16.8918 1.66663 16.6725V3.3275C1.66815 3.10865 1.75571 2.89918 1.91039 2.74435C2.06506 2.58951 2.27444 2.50175 2.49329 2.5H17.5066C17.9633 2.5 18.3333 2.87083 18.3333 3.3275V16.6725C18.3318 16.8914 18.2442 17.1008 18.0895 17.2557C17.9349 17.4105 17.7255 17.4983 17.5066 17.5H4.02329ZM16.6666 12.5V4.16667H3.33329V15.8333L10.9595 8.20711C11.35 7.81658 11.9832 7.81658 12.3737 8.20711L16.6666 12.5ZM16.6666 14.8567L11.6666 9.85667L5.68996 15.8333H16.6666V14.8567ZM6.66663 9.16667C6.2246 9.16667 5.80068 8.99107 5.48811 8.67851C5.17555 8.36595 4.99996 7.94203 4.99996 7.5C4.99996 7.05797 5.17555 6.63405 5.48811 6.32149C5.80068 6.00893 6.2246 5.83333 6.66663 5.83333C7.10865 5.83333 7.53258 6.00893 7.84514 6.32149C8.1577 6.63405 8.33329 7.05797 8.33329 7.5C8.33329 7.94203 8.1577 8.36595 7.84514 8.67851C7.53258 8.99107 7.10865 9.16667 6.66663 9.16667Z"*/}
              {/*          fill="white"*/}
              {/*        />*/}
              {/*      </svg>*/}
              {/*    </span>*/}
              {/*    Media*/}
              {/*  </Link>*/}
              {/*</li>*/}

              <li className="nav-item marg-right-2">
                <Link
                  to="/notifications"
                  className="nav-link color-white"
                  tabIndex="-1"
                  aria-disabled="true"
                >
                  <span className="px-1">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 8.33335C15 7.00727 14.4732 5.7355 13.5356 4.79782C12.5979 3.86014 11.3261 3.33335 10 3.33335C8.67394 3.33335 7.40217 3.86014 6.46449 4.79782C5.5268 5.7355 5.00002 7.00727 5.00002 8.33335V15H15V8.33335ZM16.6667 15.5559L17 16C17.0464 16.0619 17.0747 16.1355 17.0817 16.2126C17.0886 16.2897 17.074 16.3671 17.0394 16.4364C17.0048 16.5056 16.9516 16.5638 16.8857 16.6045C16.8199 16.6451 16.7441 16.6667 16.6667 16.6667H3.33335C3.25597 16.6667 3.18012 16.6451 3.1143 16.6045C3.04848 16.5638 2.99528 16.5056 2.96068 16.4364C2.92607 16.3671 2.91142 16.2897 2.91837 16.2126C2.92532 16.1355 2.95359 16.0619 3.00002 16L3.33335 15.5559V8.33335C3.33335 6.56524 4.03573 4.86955 5.28598 3.61931C6.53622 2.36907 8.23191 1.66669 10 1.66669C11.7681 1.66669 13.4638 2.36907 14.7141 3.61931C15.9643 4.86955 16.6667 6.56524 16.6667 8.33335V15.5559ZM7.91669 17.5H12.0834C12.0834 18.0526 11.8639 18.5825 11.4732 18.9732C11.0825 19.3639 10.5526 19.5834 10 19.5834C9.44749 19.5834 8.91758 19.3639 8.52688 18.9732C8.13618 18.5825 7.91669 18.0526 7.91669 17.5Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  Notification
                </Link>
              </li>

              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  style={{
                    backgroundColor: "#0000",
                    fontSize: "14px",
                    border: "0px solid"
                  }}
                  className="pr-3"
                >
                  {profile.profilePicture ? (
                    <img
                      src={profile.profilePicture}
                      className="img-fluid rounded-circle"
                      alt={profile.firstName}
                      width="50"
                      height="30"
                      style={{ height: "50px" }}
                    />
                  ) : (
                    <img
                      src="https://source.unsplash.com/random/80x80"
                      className="img-fluid rounded-circle nav-profile"
                      alt=""
                      width="50"
                    />
                  )}
                  {/* <img src={props.image} className="img-fluid rounded" alt="" /> */}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Link to="/profile" className="dropdown-item">
                    <span className="px-1">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.66699 14.6667C2.66699 13.2522 3.2289 11.8956 4.22909 10.8955C5.22928 9.89526 6.58584 9.33335 8.00032 9.33335C9.41481 9.33335 10.7714 9.89526 11.7716 10.8955C12.7718 11.8956 13.3337 13.2522 13.3337 14.6667H12.0003C12.0003 13.6058 11.5789 12.5884 10.8288 11.8383C10.0786 11.0881 9.06119 10.6667 8.00032 10.6667C6.93946 10.6667 5.92204 11.0881 5.1719 11.8383C4.42175 12.5884 4.00033 13.6058 4.00033 14.6667H2.66699ZM8.00032 8.66669C5.79032 8.66669 4.00033 6.87669 4.00033 4.66669C4.00033 2.45669 5.79032 0.666687 8.00032 0.666687C10.2103 0.666687 12.0003 2.45669 12.0003 4.66669C12.0003 6.87669 10.2103 8.66669 8.00032 8.66669ZM8.00032 7.33335C9.47366 7.33335 10.667 6.14002 10.667 4.66669C10.667 3.19335 9.47366 2.00002 8.00032 2.00002C6.52699 2.00002 5.33366 3.19335 5.33366 4.66669C5.33366 6.14002 6.52699 7.33335 8.00032 7.33335Z"
                          fill="#101214"
                        />
                      </svg>
                    </span>
                    Profile
                  </Link>
                  <Link
                    to="#"
                    className="dropdown-item"
                    tabIndex="-1"
                    aria-disabled="true"
                    onClick={() => logout()}
                  >
                    <span className="px-1">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.17634 2.53799L4.94101 3.63066C4.0118 4.28117 3.31418 5.211 2.9495 6.28506C2.58482 7.35912 2.57208 8.52148 2.91311 9.60328C3.25415 10.6851 3.9312 11.63 4.84592 12.3007C5.76065 12.9715 6.86539 13.3331 7.99968 13.3331C9.13396 13.3331 10.2387 12.9715 11.1534 12.3007C12.0682 11.63 12.7452 10.6851 13.0862 9.60328C13.4273 8.52148 13.4145 7.35912 13.0498 6.28506C12.6852 5.211 11.9876 4.28117 11.0583 3.63066L11.823 2.53799C12.7016 3.15228 13.419 3.96963 13.914 4.92057C14.409 5.87151 14.6671 6.92792 14.6663 8C14.6663 11.682 11.6817 14.6667 7.99968 14.6667C4.31768 14.6667 1.33301 11.682 1.33301 8C1.33224 6.92792 1.59034 5.87151 2.08536 4.92057C2.58039 3.96963 3.29771 3.15228 4.17634 2.53799ZM7.33301 8V1.33333H8.66634V8H7.33301Z"
                          fill="#101214"
                        />
                      </svg>
                    </span>
                    Logout
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          ) : (
            <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
              <li className="nav-item marg-right-2">
                <Link
                  to="/about-us"
                  className="nav-link color-white"
                  aria-current="page"
                  href="#"
                >
                  About Us
                </Link>
              </li>

              <li className="nav-item marg-right-2">
                <Link
                  to="/contact-us"
                  className="nav-link color-white"
                  tabIndex="-1"
                  aria-disabled="true"
                >
                  Contact Us
                </Link>
              </li>
              <li className="nav-item marg-right-2">
                <Link to="/login">
                  <CustomButton
                    text="Log In"
                    height="44px"
                    width="117px"
                    borderRadius="4px"
                    fontSize="16px"
                    fontWeight="500"
                    fontColor="#D57932"
                  />
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/create-account">
                  <CustomButton
                    text="Create Account"
                    height="44px"
                    width="156px"
                    borderRadius="4px"
                    fontSize="16px"
                    fontWeight="500"
                    fontColor="#fff"
                    backgroundColor="#D57932"
                  />
                </Link>
              </li>
            </ul>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
