import React, { useState } from "react";
import { JobDetailsModal } from "../../../../../../components/JobDetailsModal";


export const JobDetails = ({ id, name }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      {
        isModalOpen && <JobDetailsModal isCreateJobOpen={isModalOpen} setIsCreateJobOpen={setIsModalOpen} id={id} />
      }

      <div style={{ cursor: 'pointer' }} onClick={() => setIsModalOpen(true)}>{name}</div>
    </>
  )
}
