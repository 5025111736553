import React, { useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { useSignupEmployer } from "../../../../../hooks/useSignupEmployer";
import Typography from "../../../../../components/common/Typography";
import { LoadSpinner } from "../../../../../components/common/LoadSpinner";
import { enqueueSnackbar } from "notistack";
import './index.scss';


export const EmployerSignup = () => {
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: ''
  });
  const history = useHistory();
  const { mutate: submitForm, isLoading } = useSignupEmployer();
  const onSignupSubmit = (event) => {
    event.preventDefault();

    submitForm(data, {
      onSuccess: ({ token }) => {
        localStorage.setItem('token', token);
        enqueueSnackbar('Account Created Successfully', { variant: 'success' });
        history.push('/employers/dashboard');
      },
      onError: ({ message }) => {
        enqueueSnackbar(message, { variant: 'error' });
      }
    });
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData(state => ({ ...state, [name]: value }));

    if (name === 'confirmPassword') {
      if (value !== data.password) {
        event.target.setCustomValidity("Passwords do not match");
      } else {
        event.target.setCustomValidity('');
      }
    }
  }

  return (
    <>
      {
        isLoading && <LoadSpinner />
      }

      <form className='employer-signup__form' onSubmit={onSignupSubmit}>
        <div className='employer-signup__form__row'>
          <div className='employer-signup__form__row-item'>
            <Typography
              text='First Name'
              textAlign='left'
              fontSize={'12px'}
            />
            <input
              type="text"
              className="employer-signup__form-input"
              id="firstName"
              name="firstName"
              aria-describedby="firstName"
              onChange={handleInputChange}
              required
            />
          </div>
          <div className='employer-signup__form__row-item'>
            <Typography
              text='Last name'
              textAlign='left'
              fontSize={'12px'}
            />
            <input
              type="text"
              className="employer-signup__form-input"
              id="lastName"
              name="lastName"
              aria-describedby="lastName"
              onChange={handleInputChange}
              required
            />
          </div>
        </div>

        <div className='employer-signup__form__row'>
          <div className='employer-signup__form__row-item'>
            <Typography
              text='Email'
              textAlign='left'
              fontSize={'12px'}
            />
            <input
              type="email"
              className="employer-signup__form-input"
              id="email"
              name="email"
              aria-describedby="email"
              onChange={handleInputChange}
              required
            />
          </div>
          <div className='employer-signup__form__row-item'>
            <Typography
              text='Phone Number'
              textAlign='left'
              fontSize={'12px'}
            />
            <input
              type="text"
              className="employer-signup__form-input"
              id="phoneNumber"
              name="phoneNumber"
              aria-describedby="phoneNumber"
              onChange={handleInputChange}
              required
            />
          </div>
        </div>

        <div className='employer-signup__form__row'>
          <div className='employer-signup__form__row-item'>
            <Typography
              text='Password'
              textAlign='left'
              fontSize={'12px'}
            />
            <input
              type="password"
              className="employer-signup__form-input"
              id="password"
              name="password"
              aria-describedby="password"
              onChange={handleInputChange}
              required
            />
          </div>
          <div className='employer-signup__form__row-item'>
            <Typography
              text='Confirm Password'
              textAlign='left'
              fontSize={'12px'}
            />
            <input
              type="password"
              className="employer-signup__form-input"
              id="confirmPassword"
              name="confirmPassword"
              aria-describedby="confirm password"
              onChange={handleInputChange}
              required
            />
          </div>
        </div>

        <input className='employer-signup__form-button' type="submit" value="Create Account" disabled={isLoading} />
      </form>

      <Typography
        text={
          <>
            Already have an account?
            <Link to='/new-login' className='signup__login'>Login</Link>
          </>
        }
        fontSize={'14px'}
      />
    </>
  );
}
