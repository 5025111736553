export const Endpoints = {
  // employers
  signupEmployer: '/v1/auth/employer/signup',
  loginEmployer: '/v1/auth/employer/login',
  employerCreateJob: '/v1/employer/job',
  employerJob: (status) => `/v1/employer/job?status=${status}`,
  employerJobDetails: (jobId) => `/v1/employer/job/${jobId}`,
  employerJobApplicants: (jobId) => `/v1/employer/job/${jobId}/applicants`,
  employerSelectJobApplication: (jobId, jobApplicationId) => `/v1/employer/job/${jobId}/applicants/${jobApplicationId}/select`,
  employerUpdateJobStatus: (jobId) => `/v1/employer/job/${jobId}/status`,
  employerTransactions: '/v1/employer/transaction',
  employerUserMe: '/v1/employer/me',
  employerJobSeeker: (jobSeekerId) => `/v1/employer/job-seeker/${jobSeekerId}`,

  // job seekers
  signupJobSeeker: '/v1/auth/job-seeker/signup',
  loginJobSeeker: '/v1/auth/job-seeker/login',
  jobSeekerUserMe: '/v1/job-seeker/me',
  talentsGetAllJobs: (limit, categoryId) => `/v1/jobs?limit=${limit}${categoryId ? `&categoryId=${categoryId}` : ''}`,
  jobSeekersGetMyJobs: '/v1/job-seeker/job-application',
  jobSeekerTransaction: '/v1/job-seeker/transaction',
  applyToJob: (jobId) => `/v1/job-seeker/job-application/${jobId}`,
  jobSeekerMedia: '/v1/job-seeker/media',
  jobSeekerDeleteMedia: (mediaId) => `/v1/job-seeker/media/${mediaId}`,

  // common
  getCategories: '/v1/category',
  notifications: '/v1/notification'
};
