import { useQuery } from "react-query";
import { QueryKeys } from "../../utils/queryKeys";
import { apiClient } from "../../utils/ApiClient";
import { Endpoints } from "../../utils/endpoints";

const getTransactions = async () => {
  return  await apiClient.get(Endpoints.employerTransactions);
}

export const useEmployerGetTransactions = () => {
  return useQuery({
    queryKey: QueryKeys.employerTransactions,
    queryFn: getTransactions,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });
}
