import { useContext } from "react";
import styled, { ThemeContext } from "styled-components";

import { ProfileContext } from "../../context/Profile";

const Button = styled.button`
  height: ${(props) => props.height || "40px"};
  width: ${(props) => props.width};
  border: ${(props) => props.border || "1px solid #000"};
  border-radius: ${(props) => props.borderRadius || "25px"};
  margin: ${({ margin }) => margin};
  background: ${({ background }) => background};
  background-image: ${(props) => props.backgroundImage || ""};
  outline: 0;
  cursor: pointer;
  opacity: ${(props) => (props.btnLoading ? "0.3" : "1")};
  & > span {
    text-align: center;
    font-weight: ${(props) => props.fontWeight || "600"};
    font-size: ${(props) => props.fontSize || "20px"};
    font-family: Graphik;
    line-height: 22px;
    color: ${(props) => props.fontColor || "#fff"};
  }
`;

const CustomGradientButton = ({
  text,
  margin,
  backgroundImage,
  background,
  btnLoading = false,
  width,
  fontSize = "20px",
  fontWeight = "600",
  borderRadius = "25px",
  onClick,
  btnStyle,
  textStyle,
  border,
  fontColor = "#fff",
  height = "48px",
  textElement = null,
}) => {
  const theme = useContext(ThemeContext);
  const { loading } = useContext(ProfileContext);

  return (
    <Button
      disabled={loading}
      theme={theme}
      margin={margin}
      background={background}
      backgroundImage={backgroundImage}
      width={width}
      fontSize={fontSize}
      fontWeight={fontWeight}
      onClick={onClick ? onClick : null}
      btnLoading={loading}
      style={btnStyle}
      border={border}
      fontColor={fontColor}
      borderRadius={borderRadius}
      height={height}
    >
      {textElement ? textElement : <span style={textStyle}>{text}</span>}
    </Button>
  );
};

export default CustomGradientButton;
