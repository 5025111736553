import React from 'react';
import './index.scss';


export const Footer = () => {
  const year = new Date().getFullYear();
  const text = `© ShowKonnect ${year} - All Rights Reserved.`;
  return (
    <div className='footer footer__container'>
      <div className='container footer__item'>
        <p>{text}</p>
      </div>
    </div>
  );
};
