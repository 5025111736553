import { useQuery } from "react-query";
import { apiClient } from "../../utils/ApiClient";
import { Endpoints } from "../../utils/endpoints";
import { QueryKeys } from "../../utils/queryKeys";

const getEmployerJobDetails = async (id) => {
  const response = await apiClient.get(Endpoints.employerJobDetails(id));

  if (response.status) {}
  return response;
}

export const useEmployersGetJobDetailsQuery = (id) => {
  return useQuery({
    queryKey: QueryKeys.employersGetJobDetails(id),
    queryFn: () => getEmployerJobDetails(id),
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });
}
