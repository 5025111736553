import React, { useReducer, createContext, useEffect } from "react";
import axios from "axios";
import profileReducer from "../reducers/profileReducer";
import { makeApiRequest } from "../utils/validator";
import {_SUCCESS, DONELOADING, LOADING, SUCCESS} from "../reducers/types";

let user =
  localStorage.getItem("user") && localStorage.getItem("user") !== "undefined"
    ? JSON.parse(localStorage.getItem("user"))
    : "";

const initialState = {
  profile: {
    id: "" || user.id,
    firstName: "" || user.firstName,
    lastName: "" || user.lastName,
    email: "" || user.email,
    phoneNumber: "" || user.phoneNumber,
    location: "" || user.location,
    entertainmentSector: "" || user.entertainmentSector,
    bankAccountName: "" || user.bankAccountName,
    bankAccountNumber: "" || user.bankAccountNumber,
    subscribeToNotification: false,
    coverPicture: "" || user.coverPicture,
    profilePicture: "" || user.profilePicture,
    bio: "" || user.bio
  },
  jobs: [],
  allJobs: [],
  loading: false,
  banks: [],
  bankVerification: {
    "errorMessage": null,
    "verifiedAccountName": null
  },
  media: []
};

export const ProfileContext = createContext(undefined);

const ProfileProvider = ({ children }) => {
  const [{ loading, profile, jobs, allJobs, banks, bankVerification, media }, dispatch] = useReducer(
    profileReducer,
    initialState
  );

  useEffect(() => {
    getBanks();
    getUserProfile();
  }, []);

  const getUserProfile = async () => {
    dispatch({
      type: LOADING
    });

    const { request, response } = await makeApiRequest("/profile", "GET");

    if (request?.status === "error") {
      dispatch({
        type: DONELOADING
      });
      return {
        status: request.status,
        success: false,
        message: response.message
      };
    } else if (request?.status === 200) {
      localStorage.setItem("user", JSON.stringify(response.data));
      dispatch({
        type: SUCCESS,
        payload: {
          profile: response.data,
          media: response.media
        }
      });

      dispatch({
        type: DONELOADING
      });

      return {
        status: request.status === 200,
        data: response.data
      };
    }
  };

  const getProfileById = async (id) => {
    dispatch({
      type: LOADING
    });

    const { request, response } = await makeApiRequest(
      `/profile/user/${id}`,
      "GET"
    );

    if (request.status === "error") {
      dispatch({
        type: DONELOADING
      });
      return {
        status: request.status,
        success: false,
        message: response.message
      };
    } else if (request.status === 200) {
      dispatch({
        type: DONELOADING
      });

      return {
        status: request.status === 200,
        data: response.data,
        media: response.media
      };
    }
  };

  const updateUserProfile = async (inputValues) => {
    dispatch({
      type: LOADING
    });

    const { request, response } = await makeApiRequest(
      `/profile`,
      "PATCH",
      inputValues
    );

    if (request.status === "error") {
      return { success: false, message: response.message };
    } else if (request.status === "success") {
      await getUserProfile();
    }
    dispatch({
      type: DONELOADING
    });
    return { success: true, message: response.message };
  };

  const UpdateProfileImage = async (files) => {
    var formData = new FormData();

    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      formData.append(`image`, element);
    }

    dispatch({
      type: LOADING
    });

    const response = axios
      .patch(
        `https://showkonnect-bend.herokuapp.com/api/v1/profile/uploadprofilephoto`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
          }
        }
      )
      .then((res) =>
        dispatch({
          type: "PROFILEPHOTOSUCCESS",
          payload: res.data.data
        })
      );

    return { success: true, message: response.message };
  };

  const UpdateCoverImage = async (files) => {
    var formData = new FormData();

    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      formData.append(`image`, element);
    }

    dispatch({
      type: LOADING
    });

    const response = axios
      .patch(
        `https://showkonnect-bend.herokuapp.com/api/v1/profile/uploadcoverphoto`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
          }
        }
      )
      .then((res) =>
        dispatch({
          type: "COVERPHOTOSUCCESS",
          payload: res.data.data
        })
      );

    return { success: true, message: response.message };
  };

  const createJob = async (data) => {
    dispatch({
      type: LOADING
    });

    const { request, response } = await makeApiRequest(
      `/job/create-job`,
      "POST",
      {
        ...data,
        postedBy: profile.id
      }
    );

    if (request.status === "error") {
      dispatch({
        type: DONELOADING
      });
      return { success: false, message: response.message };
    } else if (request.status === 200) {
      dispatch({
        type: DONELOADING
      });
      await getUserJobs();
    }

    return { success: true, message: "Job created" };
  };

  const getJobs = async () => {
    dispatch({
      type: LOADING
    });

    const { request, response } = await makeApiRequest("/job/all-jobs", "GET");

    if (request.status === 200) {
      dispatch({
        type: "ALLJOBSSUCCESS",
        payload: response.data
      });
    }
    dispatch({
      type: DONELOADING
    });

    return {
      status: request.status === 200,
      data: response.data
    };
  };

  const getUserJobs = async () => {
    dispatch({
      type: LOADING
    });

    const { request, response } = await makeApiRequest(`/job`, "GET");

    dispatch({
      type: "JOBSUCCESS",
      payload: response?.data
    });

    dispatch({
      type: DONELOADING
    });

    return {
      status: request?.status === 200,
      data: response?.data
    };
  };

  const updateJob = async (inputValues, id) => {
    dispatch({
      type: LOADING
    });

    // eslint-disable-next-line no-unused-vars
    const response = await makeApiRequest(`/job/${id}`, "PATCH", inputValues);

    await getUserJobs();
    await getJobs();

    dispatch({
      type: DONELOADING
    });
    return { success: true, message: "Update Successful" };
  };

  const deleteJob = async (id) => {
    dispatch({
      type: LOADING
    });

    const { request, response } = await makeApiRequest(`/job/${id}`, "DELETE");
    await getUserJobs();
    await getJobs();

    dispatch({
      type: DONELOADING
    });

    if (request.status === "error") {
      return { success: false, message: response.message };
    }

    return { success: true, message: response.message };
  };

  const applyToJob = async (id) => {
    dispatch({
      type: LOADING
    });

    const { request, response } = await makeApiRequest(
      `/job/apply/${id}`,
      "PATCH"
    );

    await getUserJobs();

    dispatch({
      type: DONELOADING
    });

    if (request.status === "error") {
      return { success: false, message: response.message };
    }

    return { success: true, message: response.message };
  };

  const hire = async (jobId, applicantId) => {
    dispatch({
      type: LOADING
    });

    const { response } = await makeApiRequest(
      `/job/${jobId}/select/${applicantId}`,
      "PATCH"
    );

    dispatch({
      type: DONELOADING
    });
    // console.log("request => ", request);
    // console.log("response => ", response);
    if (response.status === "error") {
      return { success: false, message: response.message };
    }
    await getUserJobs();

    return { success: true, message: response.message, data: response };
  };

  const logout = () => {
    dispatch({
      type: "LOGOUT"
    });

    localStorage.removeItem("token");
    localStorage.removeItem("user");
  };

  const getBanks = async  () => {
    // START LOADER
    dispatch({ type: LOADING });

    // make request to get banks from flutterwave
    const { request, response } = await makeApiRequest(
      `/bank`,
      'GET'
    )

    if (request && request.status === 200) {
      dispatch({
        type: _SUCCESS,
        payload: {
          banks: response.data.data
        }
      });
    }

    // END LOADER
    dispatch({ type: DONELOADING })
  };

  const validateAccount = async  (inputValues) => {
    // reset data and start loader
    dispatch({
      type: _SUCCESS,
      payload: {
        loading: true,
        bankVerification: {
          errorMessage: null,
          verifiedAccountName: null
        }
      }
    });

    // validate account
    const {request, response} = await makeApiRequest(
      '/bank/validate-account',
      'POST', inputValues
    );

    if (request.status === 200) {
      // set error message
      if (response.data.status === "error") {
        dispatch({
          type: _SUCCESS,
          payload: {
            bankVerification: {
              "errorMessage": response.data.message,
              "verifiedAccountName": null
            }
          }
        });
      } else {
        // set success data
        dispatch({
          type: _SUCCESS,
          payload: {
            bankVerification: {
              "errorMessage": null,
              "verifiedAccountName": response.data.data.account_name
            },
            profile: {
              ...profile,
              bankAccountName: response.data.data.account_name,
              bankAccountNumber: inputValues.accountNumber
            }
          }
        });
      }
    }

    dispatch({ type: DONELOADING });
  };

  const createMedia = async (data) => {
    dispatch({
      type: LOADING
    });

    const { request, response } = await makeApiRequest(
      `/media`,
      "POST",
      { ...data }
    );

    if (request.status === "error") {
      dispatch({
        type: DONELOADING
      });
      return { success: false, message: response.message };
    } else if (request.status === 201) {
      dispatch({
        type: _SUCCESS,
        payload: {}
      });
    }

    return { success: true, message: "Added Successfully." };
  }

  const deleteMedia = async (id) => {
    dispatch({
      type: LOADING
    });

    const { request, response } = await makeApiRequest(
      `/media/${id}`,
      "DELETE"
    );

    if (request.status === "error") {
      dispatch({
        type: DONELOADING
      });
      return { success: false, message: response.message };
    } else if (request.status === 200) {
      dispatch({
        type: _SUCCESS,
        payload: {}
      });
    }

    return { success: true, message: "Deleted Successfully." };
  }

  const updateLoading = async (loading) => {
    if (loading) {
      dispatch({
        type: LOADING
      });
    } else {
      dispatch({
        type: DONELOADING
      });
    }
  }

  return (
    <ProfileContext.Provider
      value={{
        getUserProfile,
        getProfileById,
        getJobs,
        getUserJobs,
        createJob,
        updateJob,
        deleteJob,
        applyToJob,
        hire,
        logout,
        profile,
        jobs,
        allJobs,
        loading,
        updateUserProfile,
        UpdateCoverImage,
        UpdateProfileImage,
        getBanks,
        banks,
        validateAccount,
        bankVerification,
        media,
        createMedia,
        deleteMedia,
        updateLoading
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;
