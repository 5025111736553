import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Divider } from 'antd';
import { FaCalendarAlt, FaMapMarker, FaMoneyBill, FaClipboardList } from 'react-icons/fa';
import './index.scss';

export const ViewTransaction = ({isViewTransactionOpen, data, closeModal}) => {

  return (
    <Modal show={isViewTransactionOpen} onHide={closeModal} size='lg'>
      <Modal.Body>
        <button className="btn close" onClick={closeModal}>
          <span aria-hidden="true">×</span>
        </button>

        <div className='container view-transactions__container'>
          <h2 className='view-transactions__header view-transactions-bold'>{data.name}</h2>
          {/*<p className='view-transactions__description'>Lorem ipsum dolor sit a m et, consectetur adipiscing elit, sed do eiusmod tempor incididunt egseeut labore e t dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitadation  ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eufugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in</p>*/}

          {/*<div className='row view-transactions__values'>*/}
          {/*  <div className='col-sm-12 col-md-4 view-transactions__fa-icons'><FaCalendarAlt /> <span className='view-transactions-bold'>Start Date:</span> 12-12-2022</div>*/}
          {/*  <div className='col-sm-12 col-md-4'></div>*/}
          {/*  <div className='col-sm-12 col-md-4 view-transactions__fa-icons'><FaCalendarAlt /> <span className='view-transactions-bold'>End Date:</span> 12-12-2022</div>*/}
          {/*</div>*/}

          {/*<div className='row view-transactions__values'>*/}
          {/*  <div className='col-sm-12 col-md-4 view-transactions__fa-icons'><FaMapMarker /> <span className='view-transactions-bold'>Location:</span> Lagos</div>*/}
          {/*  <div className='col-sm-12 col-md-4'></div>*/}
          {/*  <div className='col-sm-12 col-md-4 view-transactions__fa-icons'><FaClipboardList /> <span className='view-transactions-bold'>Category:</span> Music</div>*/}
          {/*</div>*/}

          {/*<div className='row view-transactions__values'>*/}
          {/*  <div className='col-sm-12 col-md-4 view-transactions__fa-icons'><FaMoneyBill/> <span className='view-transactions-bold'>Price:</span> {data.price}</div>*/}
          {/*</div>*/}

          <Divider />

          <div className='view-transactions__candidate-info'>
            {/*<p><span className='view-transactions-bold'>Selected Candidate:</span> John Doe</p>*/}
            <p><span className='view-transactions-bold'>Status:</span> {data.status}</p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
};
