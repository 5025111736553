import React from 'react';
import Loader from 'react-loader-spinner';


export const LoadSpinner = () => {
  return (
    <div className='load-spinner'>
      <Loader
        type="MutatingDots"
        color="#D57932"
        height={100}
        width={100}
        visible={true}
        timeout={500000}
      />
    </div>
  )
}
