import { useQuery } from "react-query";
import { QueryKeys } from "../../utils/queryKeys";
import { apiClient } from "../../utils/ApiClient";
import { Endpoints } from "../../utils/endpoints";


const getNotifications = async () => {
  return await apiClient.get(Endpoints.notifications);
}

export const useNotificationQuery = () => {
  return useQuery({
    queryKey: QueryKeys.getNotifications,
    queryFn: getNotifications,
    staleTime: 300000,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });
}
