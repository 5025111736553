import { config } from "../config";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const baseUrl = config.bendUrl;

const makeApiRequest = async (url, method = "GET", requestBody = {}) => {
  const token = localStorage.getItem("token");
  try {
    const options = {
      method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    };
    const extendedOptions =
      method !== "GET"
        ? { ...options, body: JSON.stringify(requestBody) }
        : options;
    const response = await fetch(`${baseUrl}${url}`, extendedOptions);

    return {
      request: await response,
      response: await response.json()
    };
    // return await response.json();
  } catch (error) {
    // if (error.response) {
    //   // client received an error response (5xx, 4xx)
    //   throw Error(error.response);
    // } else if (error.request) {
    //   // client never received a response, or request never left
    //   throw Error("There seems to be a network problem");
    // }
    return error;
  }
};

const validator = (data, requiredFields = []) => {
  const errors = {};
  requiredFields.forEach((field) => {
    if (field === "validEmail") {
      if (data.email && !emailRegex.test(data.email.trim())) {
        errors.email = "Please enter a valid email address";
      }
    } else if (field === "passwordLength") {
      if (data.password && data.password.length < 6) {
        errors.password = "Password must be at least 6 characters";
      }
    } else {
      if (!data[field]) {
        errors[
          field
        ] = `Please fill in ${field}. Also be mindful of white spaces`;
      }

      if (data[field] && !data[field].trim()) {
        errors[
          field
        ] = `Please fill in ${field}. Also be mindful of white spaces`;
      }
    }
  });

  return {
    errors,
    isValid: !Object.keys(errors).length
  };
};

export { validator, makeApiRequest };
