import React, { useState, useEffect, useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import { enqueueSnackbar } from "notistack";
import Typography from "../../../../../../components/common/Typography";
import { LoadSpinner } from "../../../../../../components/common/LoadSpinner";
import CustomGradientButton from "../../../../../../components/common/CustomGradientButton";
import { useCategoryQuery } from "../../../../../../hooks/useCategoryQuery";
import { useJobSeekerUpdateProfile } from "../../../../../../hooks/mutations/useJobSeekerUpdateProfile";
import { config } from "../../../../../../config";
import './index.scss';

export const EditProfile = ({ shouldShowEditModal, closeModal, userDetails }) => {
  const [userData, setUserData] = useState(
    {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      categoryId: '',
      bio: '',
      profilePicture: config.defaultProfilePicture
    }
  );
  const { data: categoryData } = useCategoryQuery();

  const { mutate: updateJobSeekerProfile, isLoading: isUpdating} = useJobSeekerUpdateProfile();

  const categoryOptionData = useMemo(() => {
    if (!categoryData || !categoryData.hasOwnProperty('data')) return [];
    else return categoryData.data;
  }, [categoryData]);

  const onChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
  }

  const handleOnSubmit = (event) => {
    event.preventDefault();

    updateJobSeekerProfile({
      firstName: userData.firstName,
      lastName: userData.lastName,
      phoneNumber: userData.phoneNumber,
      profilePicture: userData.profilePicture,
      categoryId: userData.categoryId,
      bio: userData.bio
    }, {
      onSuccess: (response) => {
        enqueueSnackbar(response.message, { variant: 'success' });
      },
      onError: (error) => {
        enqueueSnackbar('Error: Please try again later', { variant: 'error' });
      }
    });
  }

  useEffect(() => {
    if (!userDetails) return;

    setUserData({
      firstName: userDetails?.firstName,
      lastName: userDetails?.lastName,
      email: userDetails?.email,
      phoneNumber: userDetails?.phoneNumber,
      categoryId: userDetails?.categoryId,
      profilePicture: config.defaultProfilePicture,
      bio: userDetails?.bio
    });
  }, [userDetails]);

  const isLoading = useMemo(() => {
    return isUpdating;
  }, [isUpdating]);

  return (
    <Modal show={shouldShowEditModal} onHide={closeModal} size='lg'>
      <Modal.Body>
        <button className="btn close" onClick={closeModal}>
          <span aria-hidden="true">×</span>
        </button>

        <div className='container edit-profile__container'>
          <h2 className='edit-profile__header edit-profile-bold'>Edit Profile</h2>
          {
            isLoading && <LoadSpinner />
          }

          <form onSubmit={handleOnSubmit}>
            <div className='row'>
              <div className='col-sm-12 col-md-6 edit-profile__form__row-space'>
                <Typography
                  text="First Name"
                  color="#536474"
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="16px"
                  textAlign="left"
                  padding="0"
                />

                <input
                  type="text"
                  className="edit-profile__form__input"
                  id="firstName"
                  name="firstName"
                  aria-describedby="First Name"
                  onChange={onChange}
                  value={userData.firstName}
                  required
                />
              </div>
              <div className='col-sm-12 col-md-6 edit-profile__form__row-space'>
                <Typography
                  text="Last Name"
                  color="#536474"
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="16px"
                  textAlign="left"
                  padding="0"
                />

                <input
                  type="text"
                  className="edit-profile__form__input"
                  id="lastName"
                  name="lastName"
                  aria-describedby="Last Name"
                  onChange={onChange}
                  value={userData.lastName}
                  required
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12 col-md-6 edit-profile__form__row-space'>
                <Typography
                  text="Email Address"
                  color="#536474"
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="16px"
                  textAlign="left"
                  padding="0"
                />

                <input
                  type="text"
                  className="edit-profile__form__input"
                  id="email"
                  name="email"
                  aria-describedby="Email"
                  onChange={onChange}
                  value={userData.email}
                  disabled={true}
                  required
                />
              </div>
              <div className='col-sm-12 col-md-6 edit-profile__form__row-space'>
                <Typography
                  text="Entertainment Category"
                  color="#536474"
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="16px"
                  textAlign="left"
                  padding="0"
                />

                <select
                  className='edit-profile__form__select'
                  id='categoryId'
                  name='categoryId'
                  required
                  onChange={onChange}
                >
                  {
                    categoryOptionData.map(category => (
                      <option name='categoryId' key={category?.id} value={category.id} selected={category?.id === userData?.categoryId}>
                        {category.name}
                      </option>
                    ))
                  }
                </select>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12 edit-profile__form__row-space'>
                <Typography
                  text="Profile Biography"
                  color="#536474"
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="16px"
                  textAlign="left"
                  padding="0"
                />

                <textarea
                  className='edit-profile__form__textarea'
                  maxLength={1000} rows={10}
                  placeholder='Input profile biography...' required
                  onChange={onChange}
                  value={userData.bio}
                  name='bio'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12 edit-profile__form__row-space'>
                <Typography
                  text="Phone Number"
                  color="#536474"
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="16px"
                  textAlign="left"
                  padding="0"
                />

                <input
                  type="text"
                  className="edit-profile__form__input"
                  id="phoneNumber"
                  name="phoneNumber"
                  aria-describedby="Phone Number"
                  onChange={onChange}
                  value={userData.phoneNumber}
                  required
                />
              </div>
            </div>
            {/*<div className='row'>*/}
            {/*  <div className='col-sm-12 col-md-6 edit-profile__form__row-space'>*/}
            {/*    <Typography*/}
            {/*      text="Location"*/}
            {/*      color="#536474"*/}
            {/*      fontWeight="400"*/}
            {/*      fontSize="12px"*/}
            {/*      lineHeight="16px"*/}
            {/*      textAlign="left"*/}
            {/*      padding="0"*/}
            {/*    />*/}

            {/*    <input*/}
            {/*      type="text"*/}
            {/*      className="edit-profile__form__input"*/}
            {/*      id="location"*/}
            {/*      name="location"*/}
            {/*      aria-describedby="Location"*/}
            {/*      onChange={() => {}}*/}
            {/*      value={''}*/}
            {/*      disabled*/}
            {/*      required*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*  <div className='col-sm-12 col-md-6 edit-profile__form__row-space'>*/}
            {/*    <Typography*/}
            {/*      text="Entertainment Sector"*/}
            {/*      color="#536474"*/}
            {/*      fontWeight="400"*/}
            {/*      fontSize="12px"*/}
            {/*      lineHeight="16px"*/}
            {/*      textAlign="left"*/}
            {/*      padding="0"*/}
            {/*    />*/}

            {/*    <input*/}
            {/*      type="text"*/}
            {/*      className="edit-profile__form__input"*/}
            {/*      id="entertainmentSector"*/}
            {/*      name="entertainmentSector"*/}
            {/*      aria-describedby="Entertainment Sector"*/}
            {/*      onChange={() => {}}*/}
            {/*      value={userData.category}*/}
            {/*      disabled*/}
            {/*      required*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className='row'>*/}
            {/*  <div className='col-sm-12 col-md-6 edit-profile__form__row-space'>*/}
            {/*    <Typography*/}
            {/*      text="Bank"*/}
            {/*      color="#536474"*/}
            {/*      fontWeight="400"*/}
            {/*      fontSize="12px"*/}
            {/*      lineHeight="16px"*/}
            {/*      textAlign="left"*/}
            {/*      padding="0"*/}
            {/*    />*/}

            {/*    <input*/}
            {/*      type="text"*/}
            {/*      className="edit-profile__form__input"*/}
            {/*      id="bank"*/}
            {/*      name="bank"*/}
            {/*      aria-describedby="Bank"*/}
            {/*      onChange={() => {}}*/}
            {/*      value={''}*/}
            {/*      disabled*/}
            {/*      required*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*  <div className='col-sm-12 col-md-6 edit-profile__form__row-space'>*/}
            {/*    <Typography*/}
            {/*      text="Account Number"*/}
            {/*      color="#536474"*/}
            {/*      fontWeight="400"*/}
            {/*      fontSize="12px"*/}
            {/*      lineHeight="16px"*/}
            {/*      textAlign="left"*/}
            {/*      padding="0"*/}
            {/*    />*/}

            {/*    <input*/}
            {/*      type="text"*/}
            {/*      className="edit-profile__form__input"*/}
            {/*      id="accountNumber"*/}
            {/*      name="accountNumber"*/}
            {/*      aria-describedby="Account Number"*/}
            {/*      onChange={() => {}}*/}
            {/*      value={''}*/}
            {/*      disabled*/}
            {/*      required*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className='row'>
              <button className='edit-profile__form__save-button'>Save Changes</button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  )
}
