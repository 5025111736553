import { useMutation } from "react-query";
import { apiClient } from "../../utils/ApiClient";
import { Endpoints } from "../../utils/endpoints";


const selectJobApplicant = async ({ jobId, jobApplicationId }) => {
  const response = await apiClient.post(Endpoints.employerSelectJobApplication(jobId, jobApplicationId));

  if (response.status === 'error') {
    throw new Error(response.errors);
  }

  return response;
}

export const useSelectJobApplicant = () => {
  return useMutation(
    selectJobApplicant
  );
}
