import { NewNavbar } from "../new-navbar";
import React from "react";

export const Layout = ({ children }) => {
  return (
    <div className="container-fluid">
      <div className="row flex-nowrap">

        <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0">
          <NewNavbar />
        </div>

        <div className="col col-9">
          {children}
        </div>
      </div>
    </div>
  );
}
