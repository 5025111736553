import React, {useMemo, useRef, useState} from "react";
import { Link, useHistory } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import Typography from "../../../../../components/common/Typography";
import { LoadSpinner } from "../../../../../components/common/LoadSpinner";
import { useCategoryQuery } from "../../../../../hooks/useCategoryQuery";
import { useSignupJobSeeker } from "../../../../../hooks/mutations/useSignupJobSeeker";
import './index.scss';


export const EntertainerSignup = () => {
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    location: '',
    categoryId: '',
    password: '',
    confirmPassword: ''
  });
  const history = useHistory();
  const categoryInput = useRef();
  const { data: categoryData } = useCategoryQuery();
  const { mutate, isLoading } = useSignupJobSeeker();
  const categoryOptionData = useMemo(() => {
    if (!categoryData || !categoryData.hasOwnProperty('data')) return [];
    else return categoryData.data;
  }, [categoryData]);

  const onSignupSubmit = (event) => {
    event.preventDefault();

    if (categoryInput.current.value === '' || categoryInput.current.value === '-- select an option --') {
      return categoryInput.current.setCustomValidity(
        `Select a Entertainment Sector`
      )
    }

    const { confirmPassword, ...signupFormData } = data;
    mutate(signupFormData, {
      onSuccess: ({ token }) => {
        localStorage.setItem('token', token);
        enqueueSnackbar('Account Created Successfully', { variant: 'success' });
        history.push('/dashboard');
      },
      onError: ({ message }) => {
        enqueueSnackbar(message, { variant: 'error' });
      }
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((state) => ({ ...state, [name]: value }))
  };

  const onHandleConfirmPasswordInput = (event) => {
    if (event.target.value !== data.password) {
      return event.target.setCustomValidity(
        `Password mismatch`
      );
    }

    return event.target.setCustomValidity('');
  }

  return (
    <>
      {
        isLoading && <LoadSpinner />
      }

      <form className='entertainer-signup__form' onSubmit={onSignupSubmit}>
        <div className='entertainer-signup__form__row'>
          <div className='entertainer-signup__form__row-item'>
            <Typography
              text='First Name'
              textAlign='left'
              fontSize={'12px'}
            />
            <input
              type="text"
              className=""
              id="firstName"
              name="firstName"
              aria-describedby="firstName"
              onChange={handleInputChange}
              required
            />
          </div>
          <div className='entertainer-signup__form__row-item'>
            <Typography
              text='Last name'
              textAlign='left'
              fontSize={'12px'}
            />
            <input
              type="text"
              className=""
              id="lastName"
              name="lastName"
              aria-describedby="lastName"
              onChange={handleInputChange}
              required
            />
          </div>
        </div>

        <div className='entertainer-signup__form__row-single'>
          <Typography
            text='Email Address'
            textAlign='left'
            fontSize={'12px'}
          />
          <input
            type="email"
            className=""
            id="email"
            name="email"
            aria-describedby="Email"
            onChange={handleInputChange}
            required
          />
        </div>

        <div className='entertainer-signup__form__row'>
          <div className='entertainer-signup__form__row-item'>
            <Typography
              text='Phone Number'
              textAlign='left'
              fontSize={'12px'}
            />
            <input
              type="text"
              className=""
              id="phoneNumber"
              name="phoneNumber"
              aria-describedby="phoneNumber"
              onChange={handleInputChange}
              required
            />
          </div>
          <div className='entertainer-signup__form__row-item'>
            <Typography
              text='Location'
              textAlign='left'
              fontSize={'12px'}
            />
            <input
              type="text"
              className=""
              id="location"
              name="location"
              aria-describedby="location"
              onChange={handleInputChange}
              required
            />
          </div>
        </div>

        <div className='entertainer-signup__form__row-single'>
          <Typography
            text='Entertainment Sector'
            textAlign='left'
            fontSize={'12px'}
          />

          <select
            className='form-select'
            id='categoryId'
            name='categoryId'
            required
            onChange={(e) => {
              const { name, value } = e.target;

              const category = categoryOptionData.find((data) => value == data.name);
              setData((state) => ({ ...state, [name]: category.id }));
              categoryInput.current.setCustomValidity('');
            }}
            ref={categoryInput}
          >
            <option disabled selected> -- select an option -- </option>
            {
              categoryOptionData.map((state, key) => (
                <option key={key} value={state.name}>
                  {state.name}
                </option>
              ))
            }
          </select>
        </div>

        <div className='entertainer-signup__form__row-single'>
          <Typography
            text='Password'
            textAlign='left'
            fontSize={'12px'}
          />
          <input
            type="password"
            className=""
            id="password"
            name="password"
            aria-describedby="password"
            onChange={handleInputChange}
            required
            minLength={6}
          />
        </div>

        <div className='entertainer-signup__form__row-single'>
          <Typography
            text='Confirm Password'
            textAlign='left'
            fontSize={'12px'}
          />
          <input
            type="password"
            className=""
            id="confirmPassword"
            name="confirmPassword"
            aria-describedby="confirmPassword"
            onChange={handleInputChange}
            onInput={onHandleConfirmPasswordInput}
            required
            minLength={6}
          />
        </div>

        <input className='submit-button' type="submit" value="Create Account"/>
      </form>

      <Typography
        text={
          <>
            Already have an account?
            <Link to='/new-login' className='signup__login'>Login</Link>
          </>
        }
        fontSize={'14px'}
      />
    </>
  )
}
