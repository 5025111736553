import React from "react";
import AuthenticationProvider from "./context/Authentication";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { SnackbarProvider } from 'notistack';
import AppIndex from "./pages/AppIndex";

import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";
import "@fortawesome/fontawesome-free/js/brands";
import ProfileProvider from "./context/Profile";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient();

const App = () => {
  return (
    <SnackbarProvider anchorOrigin={ { horizontal: 'right', vertical: 'top' }}>
      <AuthenticationProvider>
        <ProfileProvider>
          <QueryClientProvider client={queryClient}>
            <div className="App">
              <AppIndex />
              <ReactQueryDevtools initialIsOpen={true} />
            </div>
          </QueryClientProvider>
        </ProfileProvider>
      </AuthenticationProvider>
    </SnackbarProvider>
  );
};

export default App;
