import React, { useMemo, useState } from 'react';
import { Navbar } from "../common-blocks/navbar";
import { Footer } from "../common-blocks/footer";
import { Table, Pagination } from "antd";
import { ViewTransaction } from "./blocks/viewTransaction";
import { useEmployerGetTransactions } from "../../../../hooks/queries/useEmployerGetTransactions";
import './index.scss';


export const Transactions = () => {
  const [isViewTransactionOpen, setIsViewTransactionOpen] = useState(false);
  const [transactionData, setTransactionData] = useState({});
  const { data: transactionsData } = useEmployerGetTransactions();

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, data) => {
        return <div className='transactions__clickable' onClick={() => openViewTransaction(data)}>{text}</div>;
      },
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (text, data) => {
        return <div className='transactions__clickable' onClick={() => openViewTransaction(data)}>{text}</div>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text, data) => {
        return <div className='transactions__clickable' onClick={() => openViewTransaction(data)}>{text}</div>;
      },
    },
  ];

  const dataSource = useMemo(() => {
    if (!transactionsData) return []

    const result = [];

    transactionsData?.data?.map((data, index) => result.push(
      {
        id: index + 1,
        name: <span><b>Escrow payment for:</b> {data.job.title}</span>,
        price: data.job.amount,
        status: data.status,
        date: data.createdAt.substring(0, 10),
        job: data.job
      }
    ));

    return result;
  }, [transactionsData]);

  const openViewTransaction = (data) => {
    setIsViewTransactionOpen(true);
    setTransactionData(data);
  };

  const closeViewTransaction = () => {
    setTransactionData({});
    setIsViewTransactionOpen(false);
  };

  return (
    <div className='transactions__main'>
      <Navbar />

      <ViewTransaction  isViewTransactionOpen={isViewTransactionOpen} data={transactionData} closeModal={closeViewTransaction} />

      <div className='transactions__container container'>
        <div className='transactions__page'>
          <h2 className='transactions__page__header transactions-bold'>Transactions</h2>
          <p className='transactions__page__details'>Count your account's payment history</p>
        </div>

        <div className='transactions__table'>
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={
              {
                hideOnSinglePage: true
              }
            }
          />
        </div>
        {/*<div className='transactions__pagination'>*/}
        {/*  <Pagination defaultCurrent={1} total={50} />*/}
        {/*</div>*/}
      </div>

      <Footer />
    </div>
  );
}
