import React from "react";
import { FaTrash } from "react-icons/fa";
import { enqueueSnackbar } from "notistack";
import { useJobSeekerDeleteYoutubeMedia } from "../../hooks/mutations/useJobSeekerDeleteYoutubeMedia";


const MediaFrame = ({ youtubeLink, id, isPublic, fetchMedia }) => {
  const { mutate: deleteYoutubeMedia } = useJobSeekerDeleteYoutubeMedia();

  const handleMediaDelete = async (event) => {
    event.preventDefault();
    const result = window.confirm(
      "Are you certain you want to delete media?"
    );
    if (result) {
      deleteYoutubeMedia(
        {
          id
        }, {
          onSuccess: (response) => {
            enqueueSnackbar(response.message, { variant: 'success' });
            fetchMedia();
          },
          onError: () => {
            enqueueSnackbar('Error: Please try again later', { variant: 'error' });
          }
        }
      );
    }
  }

  return (
    <div className="media-frame">
      {
        !isPublic &&
        <FaTrash
          className="delete"
          size={20}
          onClick={handleMediaDelete}
        />
      }
      <iframe
        src={youtubeLink}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
        className="iframe"
      />
    </div>
  )
}

export default MediaFrame;
