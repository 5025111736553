import React, { useMemo } from "react";
import { Pagination, Table } from "antd";
import { useEmployerGetAllJobs } from "../../../../../../hooks/useEmployerGetAllJobs";
import { JobDetails } from "../JobDetails";
import { MakePaymentButton } from "../MakePaymentButton";

export const PaymentPendingJobs = () => {
  const { data: pendingJobs } = useEmployerGetAllJobs('payment-pending');
  const pendingJobsDataSource = useMemo(() => {
    if (!pendingJobs) return [];

    const options = [];

    pendingJobs?.data?.map((job, index) => {
      options.push({
        id: index + 1,
        jobId: job.id,
        name: job.title,
        date: job.date.substring(0, 10),
        amount: job.amount,
        totalAmount: job.totalAmount,
        tax: job.tax,
        commission: job.commission
      })
    })

    return options;
  }, [pendingJobs]);
  const pendingPaymentColumns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, { id, name, jobId }) => (
        <div key={id}>
          <JobDetails name={name} id={jobId} />
        </div>
      )
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
    },
    {
      title: '',
      dataIndex: 'view',
      key: 'view',
      render: (_, job) => <MakePaymentButton key={job.id} job={job} />
    },
  ];

  return (
    <div className='employers-job__table'>
      <Table
        columns={pendingPaymentColumns}
        dataSource={pendingJobsDataSource}
        pagination={
          {
            hideOnSinglePage: true
          }
        }
      />

      {/*<Pagination defaultCurrent={1} total={50} className='employers-job__table__pagination' />*/}
    </div>
  );
}
