import React from 'react';
import { Link } from "react-router-dom";
import Logo from "../../../images/SHOWKONNECT-TRANS-2.png";
import CustomButton from "../../common/CustomButton";


export const Navbar = () => {
  return (
    <nav className="navbar navbar-dark navbar-expand-lg bg-sec">
      <div className="container-fluid container">
        <Link to="/" className="navbar-brand logo-font color-pri">
          <img src={Logo} alt="ShowKonnect" className="img-fluid" width="80" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon navbar-light"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
              <li className="nav-item marg-right-2">
                <Link
                  to="/about-us"
                  className="nav-link color-white"
                  aria-current="page"
                  href="#"
                >
                  ABOUT US
                </Link>
              </li>

              <li className="nav-item marg-right-2">
                <Link
                  to="/new-login"
                  className="nav-link color-white"
                  tabIndex="-1"
                  aria-disabled="true"
                  href="#"
                >
                  LOGIN
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/new-signup">
                  <CustomButton
                    text="GET STARTED"
                    height="44px"
                    width="156px"
                    borderRadius="4px"
                    fontSize="16px"
                    fontWeight="500"
                    fontColor="#fff"
                    backgroundColor="#D57932"
                  />
                </Link>
              </li>
            </ul>
        </div>
      </div>
    </nav>
  );
}
