import { useQuery } from "react-query";
import { QueryKeys } from "../../utils/queryKeys";
import { apiClient } from "../../utils/ApiClient";
import { Endpoints } from "../../utils/endpoints";


const getEmployerUserDetails = async () => {
  return await apiClient.get(Endpoints.employerUserMe);
}

export const useEmployerUserDetails = () => {
  return useQuery({
    queryKey: QueryKeys.employerUserDetails,
    queryFn: getEmployerUserDetails,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });
}
