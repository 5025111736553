import { useState } from "react";
import { ViewJob } from "../viewJob";
import React from "react";

export const JobDetails = ({ job }) => {
  const [viewJobDetails, setViewJobDetails] = useState(false);

  return (
    <>
      <ViewJob
        isViewJob={viewJobDetails}
        data={job.job}
        closeModal={() => setViewJobDetails(false)}
      />

      <button
        className='employers-job__tab__view'
        onClick={() => setViewJobDetails(true)}
      >
        View
      </button>
    </>
  )
}
