import {FaMapMarker, FaShareAlt} from "react-icons/fa";
import React from "react";

export const JobCard = ({ job, onApply }) => {
  const { id, time, jobTitle, date, category, location } = job;
  return (
    <>
      <div className='talent-dashboard__job row'>
        <div className='col-sm-12 col-md-6'>
          <span className='talent-dashboard__job__time'>{time}</span>
          <p className='talent-dashboard__job__title'>{jobTitle}</p>
          <span className='talent-dashboard__job__date'>{date}</span>
          <div>
            <span className='talent-dashboard__job__category'>{category}</span>
            <FaShareAlt />
          </div>
          <span className='talent-dashboard__job__location'><FaMapMarker />{location}</span>
        </div>
        <div className='col-sm-12 col-md-6 my-auto d-flex flex-row-reverse'>
          <button className='talent-dashboard__job__button' onClick={() => onApply(job)}>Apply</button>
        </div>
      </div>
    </>
  )
}
