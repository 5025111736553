import React from "react";
import Modal from 'react-bootstrap/Modal';
import { useHistory, useParams } from 'react-router-dom';
import './index.scss';

export const Proposal = ({isViewProposal, closeModal, application, title}) => {
  const history = useHistory();
  const { id } = useParams();

  return (
    <Modal show={isViewProposal} onHide={closeModal} size='lg'>
      <Modal.Body>
        <button className="btn close" onClick={closeModal}>
          <span aria-hidden="true">×</span>
        </button>

        <div className='container proposal__container'>
          <div className='col-sm-12 proposal__page'>
            <h2 className='proposal__page__header'>Application</h2>
            <p className='proposal__page__details'>{title}</p>
          </div>

          <div className='proposal__content'>
            <p>
              {application?.coverLetter}
            </p>
          </div>

          <div className='proposal__view_profile'>
            <button
              className='proposal__view_profile-button'
              onClick={() => history.push(`/employers/jobs/${id}/job-seeker/${application.jobSeekerId}`)}
            >
              View Profile
            </button>
          </div>

        </div>
      </Modal.Body>
    </Modal>
  )
}
