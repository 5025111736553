import React from "react";
import { Navbar } from "../../components/v2/navbar";
import Footer from "../../components/layout/Footer";
import './index.css';

const TermsAndCondition  = () => {
  return (
    <>
        <Navbar />

        <div className="container">
          <h3>Terms & Conditions</h3>
          <p>
            These terms and conditions (“Agreement”) set forth the general terms and conditions governing your use of the “ShowKonnect” application (“Application” or “Service”) and any of its related products and services (collectively, “Services”). This Agreement is a legally binding contract between you (“User”, “you”, or “your”) and the developer of the Application (“ShowKonnect”, “we”, “us”, or “our”). By accessing and using the Application and Services, you acknowledge that you have read, understood, and agree to be bound by this Agreement.
            <br /><br />
            If you are accepting this Agreement on behalf of a business or other legal entity, you represent that you have the authority to bind that entity. In such cases, “User”, “you”, or “your” will refer to that entity. You acknowledge that this Agreement forms a contract between you and ShowKonnect, even if it is electronic and not physically signed, governing your use of the Application and Services.
          </p>

          <ol className="main-list">
            <li>
              <h4>DEFINITIONS</h4>
              <p>
                In this Agreement, the following capitalized terms have the meanings specified:
              </p>

              <ol>
                <li>
                    “<b>Arbitration</b>”  A private dispute resolution process submitted to an arbitrator, whose decision is legally binding.
                </li>

                <li>
                  "<b>Account:</b>" A user account created through Registration on the Application.
                </li>

                <li>
                  “<b>Booking:</b>” The reservation or securing of a Performer’s time for services provided through ShowKonnect.
                </li>

                <li>
                  “<b>Client:</b>” A user seeking to engage a Performer through the Application.
                </li>

                <li>
                  “<b>Dispute:</b>” Any conflict arising between a Client and a Performer.
                </li>

                <li>
                  "<b>Intellectual Property Rights:</b>" All legal rights related to copyrights, trademarks, patents, designs, inventions, and other related rights, including the right to enforce or sue for infringement.
                </li>

                <li>
                  “<b>Performance:</b>” An event or service for which a Performer is booked.
                </li>

                <li>
                  “<b>Performer:</b>” Any artist, musician, actor, director, producer, or entertainer registered on the platform to provide services for a fee.
                </li>

                <li>
                  "<b>Registration:</b>" The process of creating an Account on the Application.
                </li>

                <li>
                  "<b>Service(s):</b>" All products, services, and features offered through the Application.
                </li>

                <li>
                  "<b>User / Users:</b>" All individuals or entities using the Application, including both Clients and Performers.
                </li>
              </ol>
            </li>

            <li>
              <h4>ACCOUNTS AND MEMBERSHIP</h4>

              <ol>
                <li>
                  <b>Account Creation</b>

                  <ul className="reset">

                    <li>When you create an Account, you are responsible for safeguarding your credentials and any activities under your account.</li>

                    <li>We reserve the right to monitor new accounts before activation. Providing false or misleading information during registration may result in termination of your account.</li>

                    <li>In the event of unauthorized access or security breaches, you must notify us immediately.</li>

                    <li>We will not be liable for any damages resulting from unauthorized activities on your account.</li>

                    <li>If we determine that your account or activity harms our reputation or breaches this Agreement, we may suspend or delete your account. Re-registration may be allowed at our discretion.</li>

                    <li>We may block your email or IP address to prevent further account creation in case of misuse or violations.</li>

                  </ul>
                </li>

                <li>
                  <b>Registration Requirements</b>

                  <ul className="reset">
                    <li>To access most features of the Application, you must register an Account.</li>

                    <li>Visitors may access limited portions of the Application without registration.</li>

                    <li>ShowKonnect reserves the right to decline registration for lawful reasons, including operational considerations or business needs.</li>

                    <li>You may only open two accounts on the platform, one as a Client and a Performer. If you create multiple accounts, we may delete them and impose additional penalties that might restrict you from rejoining the platform.</li>

                    <li>If you are creating an Account on behalf of a company, you represent that you are authorized to bind that entity to this Agreement.</li>
                  </ul>
                </li>

                <li>
                  <b>Account Eligibility</b>

                  <ul className="reset">
                    <li>You are 18 years or older and legally able to enter into binding agreements.</li>

                    <li>You represent a legally registered business or are acting as an employee or authorized agent of such a business.</li>

                    <li>You will use the Services for lawful business purposes only.</li>

                    <li>You comply with licensing and other regulatory requirements relevant to your services.</li>
                  </ul>
                </li>

                <li>
                  <b>Profile Creation</b>

                  <ul className="reset">
                    <li>You must create a User profile (“Profile”) as part of the registration process.</li>

                    <li>Your Profile will be visible to other Users and, unless you modify your privacy settings, to the public.</li>

                    <li>All information provided on your Profile must be accurate, truthful, and up-to-date. You must promptly correct any inaccurate or misleading information.</li>

                    <li>False or misleading profiles may result in account suspension or termination.</li>
                  </ul>
                </li>

                <li>
                  <b>Account Permissions</b>

                  <ul>
                    <li>You may authorize others to use your account, such as employees or agents, provided they act on your behalf.</li>

                    <li>You may open an account on behalf of a team or group. Provided that informations are in accordance with guidelines</li>

                    <li>You remain fully responsible for all actions taken under your account, including payment obligations and service contracts.</li>

                    <li>If any authorized user violates this Agreement, it may impact your access to the Services.</li>

                    <li>Upon account closure, all linked accounts may also be closed at our discretion.</li>
                  </ul>
                </li>

                <li>
                  <b>Account Verification</b>

                  <ul className="reset">
                    <li>By creating an Account, you authorize ShowKonnect to verify your identity and business information through third-party services or official documents.</li>

                    <li>Verification may involve checking government-issued identification, business licenses, or validating your email or financial accounts.</li>

                    <li>During the verification process, certain features of your account may be temporarily restricted. Full access will be restored upon successful verification.</li>

                    <li>Failure to provide requested documentation may result in account suspension or termination.</li>
                  </ul>
                </li>

                <li>
                  <b>Usernames and Passwords</b>

                  <ul className="reset">
                    <li>You are responsible for maintaining the confidentiality of your username and password.</li>

                    <li>You must not share your login credentials with anyone unless they are authorized to act on your behalf.</li>

                    <li>You authorize us to assume that anyone using your credentials is either you or someone authorized by you.</li>

                    <li>Notify us immediately if you suspect unauthorized use of your account or a breach of security.</li>

                    <li>You may not use another User’s credentials to access the Services without proper authorization.</li>
                  </ul>
                </li>
              </ol>
            </li>

            <li>
              <b>User Content</b>
            </li>

            <li>
              <b>License to Use Material</b>
            </li>

            <li>
              <b>Content on the Application and Services</b>
            </li>

            <li>
              <b>Links To Other Resources</b>
            </li>

            <li>
              <b>Escrow Payment</b>
            </li>

            <li>
              <b>Release of Funds</b>
            </li>

            <li>
              <b>Charges</b>
            </li>

            <li>
              <b>Refunds and Cancellations</b>
            </li>

            <li>
              <b>Taxes</b>
            </li>

            <li>
              <b>Dispute Resolution</b>
            </li>

            <li>
              <b>Prohibited Uses</b>
            </li>

            <li>
              <b>Intellectual Property Rights</b>
            </li>

            <li>
              <b>Limitation of Liability</b>
            </li>

            <li>
              <b>Indemnification</b>
            </li>

            <li>
              <b>Severability</b>
            </li>

            <li>
              <b>Changes and Amendments</b>
            </li>

            <li>
              <b>Acceptance of Terms</b>
            </li>

            <li>
              <b>Notices</b>
            </li>
          </ol>

          <br /><br />

          <h4>DATA PROTECTIONS</h4>

          <h5>Introduction</h5>

          <p>
            We are committed to protecting the privacy and security of our users’ personal data in compliance with applicable data protection laws, including the General Data Protection Regulation (GDPR) in the European Union and the Nigerian Data Protection Regulation (NDPR) in Nigeria. This section outlines how we collect, store, manage, and process user data in accordance with these standards.
          </p>

          <h5>Data Collection</h5>

          <p>
            We collect personal data that is necessary for providing our services, improving user experience, and complying with legal requirements. Personal data may include, but is not limited to:
          </p>

          <ul>
            <li>Contact Information: such as name, email address, phone number, and mailing address.</li>

            <li>Usage Data: such as IP addresses, device information, browsing activities, and interaction with our services.</li>

            <li>Transaction Data: details related to any purchases or transactions made on our platform.</li>

            <li>Other Voluntarily Provided Data: any additional information provided by the user when engaging with our services, surveys, or customer support.</li>
          </ul>

          <h5>Purpose of Data Collection</h5>

          <ul>
            <li>To deliver, maintain, and improve our services.</li>
            <li>To personalize user experience and provide targeted content.</li>
            <li>To process transactions and manage billing information.</li>
            <li>To comply with legal obligations and enforce our terms.</li>
          </ul>

          <h5>Data Storage and Security</h5>

          <p>We implement rigorous technical, administrative, and physical security measures to protect personal data from unauthorized access, alteration, disclosure, or destruction.</p>

          <ul>
            <li>Storage Location: Data is stored securely in data centers within the applicable jurisdictions, in line with legal requirements regarding data residency.</li>
            <li>Data Encryption: Personal data is encrypted both in transit and at rest to ensure its confidentiality.</li>
            <li>Access Control: Access to personal data is restricted to authorized personnel only, based on a need-to-know basis.</li>
            <li>Data Retention Policy: We retain personal data only for as long as necessary to fulfil the purposes for which it was collected, or as required by law.</li>
          </ul>

          <h5>Data Management and Processing</h5>

          <p>We ensure that user data is processed in compliance with relevant privacy laws. Our processing activities include:</p>

          <ul>
            <li>Legal Basis for Processing: We process personal data based on legal grounds such as user consent, contractual obligations, legal compliance, and legitimate business interests.</li>
            <li>Data Subject Rights: In line with GDPR, NDPR, and similar regulations, users have the right to access, correct, delete, and restrict processing of their personal data. Users may also object to data processing or request data portability where applicable.</li>
            <li>Automated Decision-Making: We limit the use of automated decision-making and profiling to cases where it is necessary for service provision, and where permitted by law.</li>
          </ul>

          <h5>Data Sharing and Disclosure</h5>

          <p>We do not sell or share personal data with third parties except as follows:</p>

          <ul>
            <li>Service Providers: Third-party vendors and contractors who assist us with services such as payment processing, customer support, and analytics. All vendors are contractually obligated to comply with our data protection standards.</li>
            <li>Legal Obligations: We may disclose personal data to comply with legal requirements, respond to regulatory inquiries, or protect the rights and safety of our users or the public.</li>
          </ul>

          <h5>User Rights and Choices</h5>

          <p>We respect user rights as specified under GDPR, NDPR, and similar privacy laws, and provide the following options: </p>

          <ul>
            <li>Access and Correction: Users may access or request corrections to their data.</li>
            <li>Consent Withdrawal: Users may withdraw consent to data processing activities at any time, where consent is the legal basis for processing.</li>
            <li>Data Portability: Where applicable, users may request a copy of their data in a structured, machine-readable format.</li>
            <li>Right to Complain: Users have the right to lodge a complaint with a relevant data protection authority if they believe their data rights are violated.</li>
          </ul>

          <h5>Termination</h5>

          <ol>
            <li>
              <b>Termination by the User</b><br />
              The User may terminate their account at any time by providing written notice to ShowKonnect or by using the account deactivation option available in the account settings. Upon termination, the User’s access to the platform will be removed immediately, and any pending transactions, data, or services associated with the account may be cancelled or forfeited, subject to applicable policies. No refunds or credits will be provided for partial billing periods unless specified otherwise in the Terms.
            </li>

            <li>
              <b>Termination by ShowKonnect</b><br />
              ShowKonnect reserves the right to suspend or terminate any account under the following circumstances:

              <ul>
                <li>For Cause: Immediate termination may occur if the User violates these Terms & Conditions, including misuse of the platform, fraud, or other illegal activities.</li>
                <li>For Convenience: ShowKonnect may also terminate accounts without cause by
                  providing at least 30 days’ notice to the User’s registered email. In such cases, ShowKonnect will make reasonable efforts to offer alternative options or pro-rate refunds, if applicable.</li>
              </ul>
            </li>

            <li>
              <b>Effect of Termination</b><br />
              Upon termination by either party, the User’s access to the account and platform services will be disabled. ShowKonnect may, at its discretion, retain or delete any content associated with the User’s account in accordance with its Data Retention Policy and Privacy Policy. Any outstanding obligations, such as payments due or return of borrowed assets, must be fulfilled within 15 days of termination.
            </li>

            <li>
              <b>Modification or Suspension of Services</b> <br />
              ShowKonnect reserves the right to modify, suspend, or discontinue its services, temporarily or permanently, with prior notice, where feasible. ShowKonnect shall not be liable to the User or third parties for any consequences arising from such actions, except as provided by law.
            </li>
          </ol>

          <h5>FORCE MAJEURE</h5>

          <h5>Force Majeure Events</h5>

          <p>ShowKonnect shall not be liable for any failure to perform its obligations under this agreement if such failure is caused by events beyond its reasonable control, including but not limited to natural disasters (e.g., earthquakes, floods, hurricanes), pandemics, acts of terrorism, war, civil unrest, government actions, strikes, labor disputes, cyber-attacks, internet outages, technical failures, power failures, or any other event deemed a Force Majeure Event. </p>

          <h5>Suspension of Obligations</h5>

          <p>Upon the occurrence of a Force Majeure Event, ShowKonnect’s obligations under this agreement shall be suspended for the duration of the Force Majeure Event. ShowKonnect will make reasonable efforts to notify affected parties and resume its obligations as soon as the Force Majeure Event subsides. </p>

          <h5>Limitations on Liability</h5>

          <p>ShowKonnect shall not be held liable for any direct or indirect damages, losses, or expenses resulting from delays or failures in performance caused by Force Majeure Events. </p>

          <h5>Alternative Performance</h5>

          <p>ShowKonnect will make commercially reasonable efforts to provide alternative performance options or resume services promptly, subject to the nature of the Force Majeure Event.</p>

          <h5>Contact Information</h5>

          <p>If you have questions about these Terms or would like to exercise your rights, please contact our Data Protection Officer (DPO) at <a href="support@showkonnect.com">support@showkonnect.com</a></p>

        </div>

        <Footer />
    </>
  )
}

export default TermsAndCondition;
