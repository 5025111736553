import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import DATA from './faq.json';

type FAQItemType = {
    question: string;
    answer: string;
    'coming soon': boolean;
};

type FAQDataType = {
    [key: string]: FAQItemType[];
};

type QuestionAnswerProps = {
    question: string;
    answer: string;
    comingSoon: boolean;
}

const QuestionAnswers = ({ question, answer, comingSoon }: QuestionAnswerProps) => {
    return comingSoon ? (
        <AccordionItem header={<p style={{ marginBottom: '0' }}>{question} <span className="faq__coming-soon">COMING SOON</span></p>}>
            <span className="faq__coming-soon">COMING SOON</span>
        </AccordionItem>
    ) :
    (
        <AccordionItem header={question}>
            {answer}
        </AccordionItem>
    )
}

type Props = {
    sectionTitle: string;
}

export const FAQSection = ({ sectionTitle }: Props) => {
    const faqData = DATA as unknown as FAQDataType;

    return (
        <div className="faq__section">
            <p className="faq__section-header">{sectionTitle}</p>

            <Accordion>
            {
                faqData[sectionTitle].map(({ question, answer, 'coming soon': comingSoon }, index: number) => <QuestionAnswers key={index} question={question} answer={answer} comingSoon={comingSoon} /> )
            }
            </Accordion>
        </div>
    )
}
