import { FAQSection } from "./FAQSection";
import { Navbar } from "../../components/v2/navbar";
import Footer from "../../components/layout/Footer";
import './index.scss';


const FAQ = () => {
  return (
    <>
      <Navbar />

      <div className="container faq">
        <>
          <h3 className="faq__header">Do You Have Questions?</h3>
          <p className="faq__sub-header">
            We have answers (well, most of the times!)
          </p>
          <p className="faq__sub-text">
            Below you'll find answers to the most common questions you may have on Showkonnect. Also, please feel free to Contact Us, if you still can't find the answer you're looking for.
          </p>
        </>

        <div className="divider" />

        <div className="row">
          <div className="col-sm-12 col-md-6">
            <FAQSection sectionTitle={"General Questions"} />
            <FAQSection sectionTitle={"Job Posting"} />
            <FAQSection sectionTitle={"Payments"} />
          </div>

          <div className="col-sm-12 col-md-6">
            <FAQSection sectionTitle={"Dispute Resolution & Refunds"} />
            <FAQSection sectionTitle={"Profile"} />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default FAQ;
