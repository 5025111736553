import React, { useMemo, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import { FaHome, FaSuitcase, FaQuestion, FaPowerOff, FaBell } from "react-icons/fa";
import { Notifications } from "./notifications";
import { config } from "../../../../../config";
import { useJobSeekerUserDetails } from "../../../../../hooks/queries/useJobSeekerUserDetails";
import Logo from '../../../../../images/SHOWKONNECT-TRANS.png'
import { useNotificationQuery } from "../../../../../hooks/queries/useNotificationQuery";
import { useReadNotifications } from "../../../../../hooks/mutations/useReadNotifications";
import { useCategoryQuery } from "../../../../../hooks/useCategoryQuery";
import "./index.scss";


export const NewNavbar = () => {
  const[isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const { pathname } = useLocation();
  const { data: userDetails } = useJobSeekerUserDetails();
  const { data: categoryData } = useCategoryQuery();

  const { data: notificationData, refetch: refetchUser } = useNotificationQuery();
  const { mutate: readNotifications } = useReadNotifications();

  const userCategory = useMemo(() => {
    if (!userDetails || !categoryData) return null;

    return categoryData?.data?.find(category => category?.id === userDetails?.data?.categoryId)
  }, [userDetails, categoryData]);

  const notifications = useMemo(() => {
    if (
      !notificationData ||
      !notificationData.hasOwnProperty('data') ||
      typeof notificationData.data !== 'object' ||
      notificationData.data.length < 1
    ) return []

    return notificationData?.data?.map(notif => ({
      id: notif?.id,
      type: notif?.type,
      data: notif?.data,
      createdAt: notif?.createdAt
    }));
  }, [notificationData]);

  const handleLogout = () => {
    localStorage.removeItem('token');
  }

  const handleOpenNotifications = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
    readNotifications({}, {
      onSuccess: () => {
        refetchUser();
      }
    });
  }

  return (
    <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100 new-navbar__container">
      <Link to='/dashboard' className="fs-5 d-none d-sm-inline new-navbar__logo">
        <img src={Logo} alt={'ShowKonnect Logo'} className={'img-fluid'} width={'80'} height={'80'} />
      </Link>

      <ul className="nav nav-pills flex-column mb-0 align-items-center align-items-sm-start" id="menu">
        <li className={`new-navbar__item ${pathname === '/dashboard' && 'new-navbar__item-active'}`}>
          <Link to='/dashboard' className="d-flex align-items-center text-decoration-none">
            <FaHome className="new-navbar__icon" size={20} />
            <span className="mx-1 d-none d-sm-inline">Home</span>
          </Link>
        </li>

        <li className={`new-navbar__item ${pathname === '/jobs' && 'new-navbar__item-active'}`}>
          <Link to='/jobs' className="d-flex align-items-center text-decoration-none">
            <FaSuitcase className="new-navbar__icon" size={20} />
            <span className="mx-1 d-none d-sm-inline">My Jobs</span>
          </Link>
        </li>

        <li className={`new-navbar__item ${pathname === '/help' && 'new-navbar__item-active'}`}>
          <Link to='/dashboard' className="d-flex align-items-center text-decoration-none">
            <FaQuestion className="new-navbar__icon" size={20} />
            <span className="mx-1 d-none d-sm-inline">Help</span>
          </Link>
        </li>

        <li className={`new-navbar__item`}>
          <span className="d-flex align-items-center text-decoration-none pointer">
            <FaBell size={20} className='new-navbar__icon' onClick={handleOpenNotifications} />
            <span className="mx-1 d-none d-sm-inline" onClick={handleOpenNotifications}>Notifications</span>
            <Notifications notifications={notifications} isNotificationsOpen={isNotificationsOpen} setIsNotificationsOpen={setIsNotificationsOpen} />
          </span>
        </li>

        <li className={`new-navbar__item ${pathname === '/logout' && 'new-navbar__item-active'}`}>
          <Link to="/" className="d-flex align-items-center text-decoration-none" onClick={handleLogout}>
            <FaPowerOff className="new-navbar__icon" size={20} />
            <span className="mx-1 d-none d-sm-inline">Logout</span>
          </Link>
        </li>
      </ul>
      <hr></hr>

      <Link to="/profile" className="new-navbar__profile text-decoration-none">
        <img
          className="new-navbar__profile__text-img"
          height={32} width={32} alt="profile"
          src={userDetails?.data?.profilePicture ? userDetails?.data?.profilePicture : config.defaultProfilePicture}
        />

        <div className="d-none d-sm-inline mx-1 new-navbar__profile__text">
          <p className="new-navbar__profile__text-name">{userDetails?.data?.firstName} {userDetails?.data?.lastName}</p>
          <p className="new-navbar__profile__text-category">{userCategory?.name}</p>
        </div>
      </Link>
    </div>
  )
}
