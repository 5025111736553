import { useMutation } from "react-query";
import { apiClient } from "../../utils/ApiClient";
import { Endpoints } from "../../utils/endpoints";


const updateProfile = async (data) => {
  const response = await apiClient.put(Endpoints.jobSeekerUserMe, { ...data });

  if (response.status === 'error') {
    throw new Error(response.errors);
  }

  return response;
}

export const useJobSeekerUpdateProfile = () => {
  return useMutation(
    updateProfile
  );
}
