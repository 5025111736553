import { useQuery } from "react-query";
import { QueryKeys } from "../../utils/queryKeys";
import { apiClient } from "../../utils/ApiClient";
import { Endpoints } from "../../utils/endpoints";

const getTransactions = async () => {
  return await apiClient.get(Endpoints.jobSeekerTransaction);
}

export const useJobSeekerGetTransactions = () => {
  return useQuery({
    queryKey: QueryKeys.jobSeekerTransaction,
    queryFn: getTransactions,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });
}
