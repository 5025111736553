import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { Avatar, Skeleton, Divider } from "antd";
import { FaAngleLeft } from "react-icons/fa";
import { Navbar } from "../common-blocks/navbar";
import { Footer } from "../common-blocks/footer";
import Media from "../../../../components/Media";
import { LoadSpinner } from "../../../../components/common/LoadSpinner";

import { useEmployerJobSeeker } from "../../../../hooks/queries/useEmployerJobSeeker";
import Typography from "../../../../components/common/Typography";

import './index.scss';


export const JobSeeker = () => {
  const { jobSeekerId } = useParams();
  const history = useHistory();

  const { data, isLoading} = useEmployerJobSeeker(jobSeekerId);

  return (
    <div className='employers-job-seeker__main'>
      <Navbar />

      {
        isLoading && <LoadSpinner />
      }

      <div className="container">
        <button className='employers-job-seeker__back' onClick={history.goBack}>
          <FaAngleLeft /> Back
        </button>

        <div className='employers-job-seeker__profile'>
          <Avatar
          src={data?.data?.jobSeeker?.profilePicture}
          size={100}
          />

          <div className='employers-job-seeker__profile-info'>
            <Typography
              text={`${data?.data?.jobSeeker?.firstName || ''} ${data?.data?.jobSeeker?.lastName || ''}`}
              textAlign='left'
              fontSize={'14px'}
              className='employers-profile__details__form__group__text'
            />
            <Typography
              text={`${data?.data?.jobSeeker?.category?.name || ''}`}
              textAlign='left'
              fontSize={'14px'}
              className='employers-profile__details__form__group__text'
            />

            { isLoading && <Skeleton paragraph={{ rows: 1 }} /> }
          </div>
        </div>

        <div className='employers-job-seeker__profile-bio'>
          <Typography
            text='Profile Biography'
            textAlign='left'
            fontSize={'14px'}
            className='employers-profile__details__form__group__text employers-job-seeker__profile-bio-p'
          />
          <Divider className='employers-job-seeker__profile-bio-divider' />
          <Typography
            text={data?.data?.jobSeeker?.bio}
            textAlign='left'
            fontSize={'14px'}
          />
        </div>

        <div className='employers-job-seeker__media'>
          <Media media={data?.data?.media} isPublic={true} fetchMedia={() => {}} />
        </div>
      </div>

      <Footer />
    </div>
  );
}
