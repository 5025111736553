import React, { useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FaCalendarAlt, FaMapMarker, FaMoneyBill, FaClipboardList } from 'react-icons/fa';
import './index.scss';
import { useCategoryQuery } from "../../../../../../hooks/useCategoryQuery";

export const ViewJob = ({ isViewJob, data, closeModal }) => {
  const { data: categoryData } = useCategoryQuery();

  const category = useMemo(() => {
    if (!categoryData || !categoryData.hasOwnProperty('data') || !data) return '';

    const cat = categoryData?.data?.find((option) => option.id === data.categoryId)
    return cat?.name;
  }, [categoryData, data]);

  return (
    <Modal show={isViewJob} onHide={closeModal} size='lg'>
      <Modal.Body>
        <button className="btn close" onClick={closeModal}>
          <span aria-hidden="true">×</span>
        </button>

        <div className='container view-job__container'>
          <h2 className='view-transactions__header view-transactions-bold'>{data.title}</h2>
          <p className='view-transactions__description'>{data.description}</p>

          <div className='row view-transactions__values'>
            <div className='col-sm-12 col-md-4 view-transactions__fa-icons'><FaCalendarAlt /> <span className='view-transactions-bold'>Date:</span> {data?.date?.substring(0, 10)}</div>
            <div className='col-sm-12 col-md-4'></div>
            <div className='col-sm-12 col-md-4 view-transactions__fa-icons'><FaCalendarAlt /> <span className='view-transactions-bold'>Price:</span> {data.amount}</div>
          </div>

          <div className='row view-transactions__values'>
            <div className='col-sm-12 col-md-4 view-transactions__fa-icons'><FaMapMarker /> <span className='view-transactions-bold'>Location:</span> {data.location}</div>
            <div className='col-sm-12 col-md-4'></div>
            <div className='col-sm-12 col-md-4 view-transactions__fa-icons'><FaClipboardList /> <span className='view-transactions-bold'>Category:</span> {category}</div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
