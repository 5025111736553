import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { ProfileContext } from "../../context/Profile";


const Button = styled.button`
  height: ${(props) => props.height || '40px'};
  width: ${(props) => props.width};
  border: ${(props) => props.border || '1px solid #000'};
  border-radius: ${(props) => props.borderRadius || '25px'};
  margin: ${({ margin }) => margin};
  background-color: ${(props) => props.backgroundColor || props.theme.primary};
  box-shadow: -3px 2px 10px rgba(0, 0, 0, 0.5);
  outline: 0;
  cursor: pointer;
  opacity: ${(props) => props.btnLoading ? '0.3' : '1'};
  & > span {
    text-align: center;
    font-weight: ${(props) => props.fontWeight || '600'};
    font-size: ${(props) => props.fontSize || '20px'};
    font-family: Mulish, sans-serif;
    //font-family: Graphik;
    line-height: 22px;
    color: ${(props) => props.fontColor || '#fff' };
  }
`;

const CustomButton = ({
    text,
    margin,
    backgroundColor,
    btnLoading = false,
    width,
    fontSize = "20px",
    fontWeight = "600",
    borderRadius = "25px",
    onClick,
    btnStyle,
    textStyle,
    border,
    fontColor = '#fff',
    height = '40px',
    textElement = null,
}) => {
    const theme = useContext(ThemeContext);
    const { loading } = useContext(ProfileContext);

    return (
        <Button
            disabled={loading}
            theme={theme}
            margin={margin}
            backgroundColor={backgroundColor}
            width={width}
            fontSize={fontSize}
            fontWeight={fontWeight}
            onClick={onClick ? onClick : null}
            btnLoading={loading}
            style={btnStyle}
            border={border}
            fontColor={fontColor}
            borderRadius={borderRadius}
            height={height}
        >
            {textElement ? textElement : <span style={textStyle}>{text}</span>}
        </Button>
    )
}

export default CustomButton;
