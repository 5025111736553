import { useQuery } from "react-query";
import { QueryKeys } from "../../utils/queryKeys";
import { apiClient } from "../../utils/ApiClient";
import { Endpoints } from "../../utils/endpoints";


const getMedia = async () => {
  return await apiClient.get(Endpoints.jobSeekerMedia);
}

export const useJobSeekerGetMedia = () => {
  return useQuery({
    queryKey: QueryKeys.jobSeekerMedia,
    queryFn: getMedia,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });
}
