import { useMutation } from "react-query";
import { apiClient } from "../../utils/ApiClient";
import { Endpoints } from "../../utils/endpoints";


const readMutation = async () => {
  const response = await apiClient.post(Endpoints.notifications);

  if (response.status === 'error') {
    throw new Error(response.errors);
  }

  return response;
}

export const useReadNotifications = () => {
  return useMutation(
    readMutation
  );
}
