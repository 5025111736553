import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Navbar } from '../common-blocks/navbar';
import { Footer } from '../common-blocks/footer';
import { CreateJob } from './blocks/createJob';
import PROFILE from '../../../../images/PROFILE.png';
import TRANSACTIONS from '../../../../images/TRANSACTIONS.png';
import MYJOBS from '../../../../images/MY_JOBS.png';
import POSTAJOB from '../../../../images/POST_A_JOB.png';
import './index.scss';


export const Dashboard = () => {
  const history = useHistory();
  const [isCreateJobOpen, setIsCreateJobOpen] = useState(false);
  const OPTIONS = [
    {
      key: 1,
      text: 'POST A JOB',
      logo: POSTAJOB,
      action: () => setIsCreateJobOpen(true)
    },
    {
      key: 2,
      text: 'TRANSACTIONS',
      logo: TRANSACTIONS,
      action: () => history.push('/employers/transactions')
    },
    {
      key: 3,
      text: 'MY JOBS',
      logo: MYJOBS,
      action: () => history.push('/employers/jobs')
    },
    {
      key: 4,
      text: 'PROFILE',
      logo: PROFILE,
      action: () => history.push('/employers/profile')
    },
  ];

  return (
    <div className='dashboard__main'>
      <Navbar />

      <CreateJob isCreateJobOpen={isCreateJobOpen} setIsCreateJobOpen={setIsCreateJobOpen} />

      <div className='dashboard__container'>
        <div className='container'>
          <div className='dashboard__options'>
            {
              OPTIONS.map(({ key, text, logo, action }) => (
                <div className='dashboard__options__item' onClick={action} key={key}>
                  <div className='dashboard__options__item__logo'>
                    <img src={logo} width={50} className='img-fluid' alt={text} />
                  </div>
                  <div className='dashboard__options__item__text'>{text}</div>
                </div>
              ))
            }
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
