import React from 'react';
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { useHistory } from "react-router-dom";
import { useEmployerUserDetails } from "../../../../../../hooks/queries/useEmployerUserDetails";


export const MakePaymentButton = ({ job }) => {
  const { data: userData } = useEmployerUserDetails();
  const history = useHistory();

  const flutterwaveConfig = {
    public_key: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
    tx_ref: `${Date.now().toString()}:${job.jobId}:${userData?.data?.id}:employer:escrowFunded`,
    amount: job?.totalAmount,
    currency: 'NGN',
    payment_options: 'card',
    customer: {
      email: userData?.data?.email,
      phone_number: userData?.data?.phoneNumber,
      name: `${userData?.data?.firstName} ${userData?.data?.lastName}`,
    },
    meta: {
      jobId: job.jobId
    },
    customizations: {
      title: 'Showkonnect',
      description: 'Escrow payment for job.',
      logo: 'https://res.cloudinary.com/dcq9wirxq/image/upload/v1698545334/efinder/dsebdomtlg963ijhlpmx.png',
    },
  };

  const handleFlutterPayment = useFlutterwave(flutterwaveConfig);

  const handleOnClick = () => {
    handleFlutterPayment({
      callback: (response) => {
        closePaymentModal();
        history.push('/employers/jobs?status=active');
        window.location.reload();
      },
      onClose: () => {
        closePaymentModal();
        window.location.reload();
      }
    });
  }


  return (
    <button className='employers-job__tab__view' onClick={handleOnClick}>Make Payment</button>
  );
}
