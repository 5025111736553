import { useQuery } from 'react-query';
import { QueryKeys } from '../utils/queryKeys';
import { apiClient } from "../utils/ApiClient";
import { Endpoints } from "../utils/endpoints";

const getCategories = async () => {
  const response = await apiClient.get(Endpoints.getCategories);
  return response;
}

export const useCategoryQuery = () => {
  return useQuery({
    queryKey: QueryKeys.getCategories,
    queryFn: getCategories,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  })
}
