import React, { useReducer, createContext, useEffect } from "react";

import authReducer from "../reducers/authReducer";
import { makeApiRequest, validator } from "../utils/validator";
import { DONELOADING, ERROR, LOADING, SUCCESS, _SUCCESS } from "../reducers/types";

export const AuthenticationContext = createContext(undefined);

let token = localStorage.getItem("token") ? localStorage.getItem("token") : "";

const initialState = {
  token: "" || token,
  loading: false,
  errorMessage: null,
  errors: {},
  user: {},
  entertainmentSector: []
};

const AuthenticationProvider = ({ children }) => {
  const [{ loading, token, entertainmentSector }, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    getEntertainmentSectors();
  }, []);

  const handleSignup = async (inputValues, requiredFields) => {
    try {
      const { isValid, errors } = validator(inputValues, requiredFields);
      if (isValid) {
        dispatch({
          type: LOADING
        });

        const { response } = await makeApiRequest(
          "/users/create-account",
          "POST",
          inputValues
        );

        if (response == undefined) {
          dispatch({
            type: DONELOADING,
            success: false,
            message: 'An error occurred. Please contact Admin.'
          });

          return {
            success: false,
            message: 'An error occurred. Please contact Admin.'
          }
        }
        else if (response.status === "fail") {
          dispatch({
            type: DONELOADING
          });
          return {
            success: false,
            message: response.message
          };
        } else if (response.status === "success") {
          dispatch({
            type: SUCCESS,
            payload: {}
          });

          return {
            success: true,
            message: response.message
          };
        } else {
          dispatch({
            type: DONELOADING
          });

          return {
            success: false,
            message: response.message
          };
        }
      } else {
        return {
          success: false,
          message: errors
        };
      }
    } catch (error) {
      dispatch({
        type: ERROR,
        errors: {
          errorMessage: `${error}`
        }
      });
    }
  };

  const activateAccountRequest = async (token) => {
    dispatch({
      type: LOADING
    });
    try {
      const { response } = await makeApiRequest(
        `/users/activate/${token}`,
        "GET"
      );

      if (response.status === "fail") {
        dispatch({
          type: DONELOADING
        });
        return {
          success: false,
          message: response.message
        };
      } else if (response.status === "success") {
        dispatch({ type: SUCCESS });

        return {
          success: true,
          message: response.message
        };
      } else {
        dispatch({ type: DONELOADING });

        return {
          success: false,
          message: "Please try again later."
        };
      }
    } catch (error) {
      dispatch({
        type: ERROR,
        errors: {
          errorMessage: `${error}`
        }
      });
    }
    return true;
  };

  const handleLogin = async (inputValues, requiredFields) => {
    try {
      const { isValid, errors } = validator(inputValues, requiredFields);
      if (isValid) {
        dispatch({
          type: LOADING
        });

        const { response } = await makeApiRequest(
          "/users/login",
          "POST",
          inputValues
        );

        if (response.status === "error") {
          dispatch({
            type: DONELOADING
          });
          return {
            success: false,
            message: response.message
          };
        } else if (response.status === "success") {
          dispatch({
            type: SUCCESS,
            payload: {}
          });

          const { token } = response;
          localStorage.setItem("token", token);
          return {
            success: true,
            message: "Login successful"
          };
        } else {
          dispatch({
            type: DONELOADING
          });

          return {
            success: false,
            message: "Please try again later."
          };
        }
      } else {
        return {
          success: false,
          message: errors
        };
      }
    } catch (error) {
      dispatch({
        type: ERROR,
        error: {
          errorMessage: `${error}`
        }
      });
    }
  };

  const getEntertainmentSectors = async () => {
    // START LOADER
    dispatch({ type: LOADING });

    // make request to get entertainment sectors
    const { response, request } = await makeApiRequest(
        "/category",
        "GET"
      );

    if (request && request.status === 200) {
      dispatch({
        type: _SUCCESS,
        payload: { entertainmentSector: response.data }
      });
    }

    // END LOADER
    dispatch({ type: DONELOADING })
  }

  const handleContactUs = async (formData) => {
    const { request, response } = await makeApiRequest(
      '/contact-us/email',
      'POST',
      formData
    );

    if (request.status === "error") {
      return { success: false, message: response.message };
    }
    return { success: true, message: response.message };
  }

  const submitForgotPassword = async (inputValues) => {
    try {
      dispatch({
        type: LOADING
      });

      const { response } = await makeApiRequest(
        "/users/forgot-password",
        "POST",
        inputValues
      );

      dispatch({
        type: DONELOADING
      });

      return response;
    } catch (error) {
      dispatch({
        type: ERROR,
        errors: {
          errorMessage: `${error}`
        }
      });
      return {
        status: "fail",
        message: "An error occurred. Please try again later."
      }
    }
  }

  const submitResetPassword = async (password, token) => {
    try {
      dispatch({
        type: LOADING
      });

      const { response } = await makeApiRequest(
        `/users/reset-password/${token}`,
        "POST",
        { password }
      );

      dispatch({
        type: DONELOADING
      });

      return response;
    } catch (error) {
      dispatch({
        type: ERROR,
        errors: {
          errorMessage: `${error}`
        }
      });
      return {
        status: "fail",
        message: "An error occurred. Please try again later."
      }
    }
  }

  return (
    <AuthenticationContext.Provider
      value={{
        handleSignup,
        loading,
        handleLogin,
        activateAccountRequest,
        token,
        entertainmentSector,
        handleContactUs,
        submitForgotPassword,
        submitResetPassword
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
