import { useQuery } from "react-query";
import { QueryKeys } from "../../utils/queryKeys";
import { apiClient } from "../../utils/ApiClient";
import { Endpoints } from "../../utils/endpoints";


const getJobSeekersAppliedJobs = async () => {
  const response = await apiClient.get(Endpoints.jobSeekersGetMyJobs);
  return response;
}

export const useJobSeekersGetMyJobs = () => {
  return useQuery({
    queryKey: QueryKeys.jobSeekersGetMyJobs,
    queryFn: getJobSeekersAppliedJobs,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  })
}
