import { useMutation } from "react-query";
import { apiClient } from "../utils/ApiClient";
import { Endpoints } from "../utils/endpoints";

const signupEmployer = async ({ firstName, lastName, email, password, phoneNumber }) => {
  const response = await apiClient.post(Endpoints.signupEmployer, {
    firstName, lastName, email, password, phoneNumber
  });

  if (response.status === 'error') throw new Error(response.errors);
  return response;
};

export const useSignupEmployer = () => {
  return useMutation(
    signupEmployer
  )
}
