import React, { useState, useEffect, useMemo } from 'react';
import { Avatar, Divider } from "antd";
import { Navbar } from "../common-blocks/navbar";
import { Footer } from "../common-blocks/footer";
import { CropSaveImage } from "../../../../components/common/CropSaveImage";
import { FaMapMarkerAlt } from 'react-icons/fa';
import Typography from "../../../../components/common/Typography";
import { useEmployerUserDetails } from "../../../../hooks/queries/useEmployerUserDetails";
import { useEmployerUpdateProfile } from "../../../../hooks/mutations/useEmployerUpdateProfile";
import { LoadSpinner } from "../../../../components/common/LoadSpinner";
import { enqueueSnackbar } from "notistack";
import { config } from "../../../../config";
import './index.scss';


export const Profile = () => {
  const [profileData, setProfileData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    profilePicture: config.defaultProfilePicture
  });
  const [file, setFile] = useState(null);
  const [cropImageUrl, setCropImageUrl] = useState('');
  const [cropProfileImage, setCropProfileImage] = useState(false);

  const { data: employerDetails, isLoading: isUserDetailsLoading } = useEmployerUserDetails();
  const { mutate: updateEmployerProfile, isLoading: isEmployerUpdateLoading } = useEmployerUpdateProfile();

  const isLoading = useMemo(() => {
    return isUserDetailsLoading || isEmployerUpdateLoading
    },
    [isUserDetailsLoading, isEmployerUpdateLoading]);

  const onChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    setProfileData({ ...profileData, [name]: value });
  }

  const handleOnSubmit = (event) => {
    event.preventDefault();

    updateEmployerProfile(profileData, {
      onSuccess: (response) => {
        enqueueSnackbar(response.message, { variant: 'success' });
      },
      onError: (error) => {
        enqueueSnackbar('Error: Please try again later', { variant: 'error' });
      }
    });
  }

  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    setCropImageUrl(URL.createObjectURL(file));
    setCropProfileImage(true)
  }

  useEffect(() => {
    if (!employerDetails || !employerDetails?.hasOwnProperty('data')) return;

    setProfileData({
      firstName: employerDetails?.data?.firstName,
      lastName: employerDetails?.data?.lastName,
      phoneNumber: employerDetails?.data?.phoneNumber,
      profilePicture: employerDetails?.data?.profilePicture ? employerDetails?.data?.profilePicture
        : config.defaultProfilePicture
    })
  }, [employerDetails]);

  return (
    <div className='employers-profile__main'>
      <Navbar />

      <div className='container employers-profile__container'>
        <div className='row employers-profile__profile'>
          {
            isLoading && <LoadSpinner />
          }

          <input type='file' accept='image/*' multiple={false} style={{ display: 'none' }} />

          <label htmlFor="profilePhoto" className='employers-profile__profile-label'>
            <input
              accept="image/*"
              id="profilePhoto"
              type="file"
              style={{ display: 'none' }}
              onChange={handleProfilePictureChange}
            />
            <Avatar
              src={profileData.profilePicture}
              style={{ cursor: 'pointer' }}
              size={100}
            />
          </label>

          <CropSaveImage
            showModal={cropProfileImage}
            closeModal={() => {
              setCropProfileImage(false);
              setCropImageUrl('');
              setFile(null);
            }}
            photoURL={cropImageUrl}
            setFormPhotoURL={(url) => setProfileData({ ...profileData, profilePicture: url })}
            setPhotoURL={(url) => setCropImageUrl(url)}
            setFile={setFile}
          />

          <div className='employers-profile__profile-name'>
            <p className='employers-profile__profile-name__text employers-profile__profile-name__text-name'>{employerDetails?.data?.firstName} {employerDetails?.data?.lastName}</p>
            <p className='employers-profile__profile-name__text employers-profile__profile-name__text-employer'>Employer</p>
          </div>
          {/*<div className='employers-profile__profile-location'>*/}
          {/*  <p className='employers-profile__profile-location-text'><FaMapMarkerAlt /> Lagos, Nigeria</p>*/}
          {/*</div>*/}
        </div>

        <div className='employers-profile__details'>
          <Divider />

          <form onSubmit={handleOnSubmit}>
            <div className='employers-profile__details__form'>
              <div className='employers-profile__details__form__group'>
                <Typography
                  text='First Name'
                  textAlign='left'
                  fontSize={'12px'}
                  color="#536474"
                  className='employers-profile__details__form__group__text'
                />
                <input
                  type="text"
                  className="employers-profile__details__form__group__input"
                  id="firstName"
                  name="firstName"
                  aria-describedby="First Name"
                  value={profileData.firstName}
                  onChange={onChange}
                  required
                />
              </div>
              <div className='employers-profile__details__form__group'>
                <Typography
                  text='Last Name'
                  textAlign='left'
                  fontSize={'12px'}
                  color="#536474"
                  className='employers-profile__details__form__group__text'
                />
                <input
                  type="text"
                  className="employers-profile__details__form__group__input"
                  id="lastName"
                  name="lastName"
                  aria-describedby="Last Name"
                  value={profileData.lastName}
                  onChange={onChange}
                  required
                />
              </div>
              <div className='employers-profile__details__form__group'>
                <Typography
                  text='Email Address'
                  textAlign='left'
                  fontSize={'12px'}
                  color="#536474"
                  className='employers-profile__details__form__group__text'
                />
                <input
                  type="email"
                  className="employers-profile__details__form__group__input"
                  id="email"
                  name="email"
                  aria-describedby="Email"
                  required
                  disabled
                  value={employerDetails?.data?.email}
                />
              </div>
              <div className='employers-profile__details__form__group'>
                <Typography
                  text='Phone Number'
                  textAlign='left'
                  fontSize={'12px'}
                  color="#536474"
                  className='employers-profile__details__form__group__text'
                />
                <input
                  type="text"
                  className="employers-profile__details__form__group__input"
                  id="phoneNumber"
                  name="phoneNumber"
                  aria-describedby="Phone Number"
                  value={profileData.phoneNumber}
                  onChange={onChange}
                  required
                />
              </div>
            </div>

            <button className='employers-profile__details__form__button' disabled={isLoading}>
              Save Changes
            </button>

          </form>
        </div>
      </div>

      <Footer />
    </div>
  );
};
