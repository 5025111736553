import { useQuery } from "react-query";
import { QueryKeys } from "../../utils/queryKeys";
import { apiClient } from "../../utils/ApiClient";
import { Endpoints } from "../../utils/endpoints";


const getEmployerJobApplicants = async (id) => {
  const response = await apiClient.get(Endpoints.employerJobApplicants(id));

  if (response.status) {}
  return response;
}

export const useGetEmployersJobApplicantsQuery = (id) => {
  return useQuery({
    queryKey: QueryKeys.employersGetJobApplicants(id),
    queryFn: () => getEmployerJobApplicants(id),
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });
}
