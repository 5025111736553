import { useMutation } from "react-query";
import { apiClient } from "../../utils/ApiClient";
import { Endpoints } from "../../utils/endpoints";

const applyToJob = async ({ jobId, coverLetter }) => {
  const response = await apiClient.post(Endpoints.applyToJob(jobId), { coverLetter });

  if (response.status === 'error') {
    throw new Error(response.errors);
  }

  return response;
}


export const useApplyToJob = () => {
  return useMutation(
    applyToJob
  )
}
