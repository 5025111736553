import { useHistory } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { enqueueSnackbar } from "notistack";


export const PrivateRouteWrapper = ({ children, userType }) => {
  const history = useHistory();
  const token = localStorage.getItem('token');

  const logout = () => {
    localStorage.removeItem('token');
    enqueueSnackbar("Unauthorised. Please log in.", { variant: "error" });
    history.push('/');
  }

  try {
    const { exp, type } = jwtDecode(token);
    if (token && new Date() < new Date(exp) && userType === type) {
      return children;
    } else {
      logout();
    }
  } catch (err) {
    logout();
  }
}

export const LoggedInWrapper = ({ children }) => {
  const history = useHistory();

  const token = localStorage.getItem('token');

  if (token) {
    try {
      const { exp, type } = jwtDecode(token);
      if (new Date() < new Date(exp)) {
        if (type === 'jobSeeker') return history.push('/dashboard');
        else return history.push('/employers/dashboard');
      } else localStorage.removeItem('token');
    } catch (err) {
      localStorage.removeItem('token');
    }
  }
  return children;
}
