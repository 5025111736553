import {_SUCCESS, DONELOADING, LOADING, SUCCESS} from "./types";

function profileReducer(state, action) {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case DONELOADING:
      return {
        ...state,
        loading: false,
      };
    case SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload.profile,
        media: action.payload.media
      };
    case "LOGOUT":
      return {
        ...state,
        loading: false,
        profile: [],
      };
    case "COVERPHOTOSUCCESS":
      return {
        ...state,
        profile: { ...state.profile, coverPicture: action.payload },
        loading: false,
      };
    case "PROFILEPHOTOSUCCESS":
      return {
        ...state,
        profile: { ...state.profile, profilePicture: action.payload },
        loading: false,
      };
    case "JOBSUCCESS":
      return {
        ...state,
        jobs: action.payload,
      };
    case "ALLJOBSSUCCESS":
      return {
        ...state,
        allJobs: action.payload,
      };
    case _SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload
      }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default profileReducer;
