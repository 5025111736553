import { LOADING, SUCCESS, ERROR, DONELOADING, LOGOUT, _SUCCESS } from "./types";


function authReducer(state, action) {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        errors: {},
        loading: true,
      };
    case DONELOADING:
      return {
        ...state,
        errors: {},
        loading: false,
      };
    case SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };

    case _SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };

    case LOGOUT:
      return {
        ...state,
        user: "",
        token: "",
      };
    case ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default authReducer;
