import React, { useState, useContext, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import { enqueueSnackbar } from "notistack";

import MediaFrame from "./MediaFrame";
import CustomGradientButton from "../common/CustomGradientButton";
import { ProfileContext } from "../../context/Profile";

import Typography from "../common/Typography";
import { LoadSpinner } from "../common/LoadSpinner";
import { useAddYoutubeMedia } from "../../hooks/mutations/useAddYoutubeMedia";

import "./index.css";


const Media = ({ media, isPublic = false, fetchMedia }) => {
  const { createMedia, getUserProfile } = useContext(ProfileContext);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [inputValues, setInput] = useState({
    youtubeLink: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const { mutate: addYoutubeMedia, isLoading: isAddingMedia } = useAddYoutubeMedia();

  const openModel = () => {
    setIsOpen( true );
  }

  const closeModal = () => {
    setIsOpen(false);
    setInput({ youtubeLink: '' })
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setInput((state) => ({ ...state, [name]: value }));
  };

  const youtube_parser = (url) => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
  }

  const handleAddMedia = async (event) => {
    event.preventDefault()
    const youtube = youtube_parser(inputValues.youtubeLink);

    if (youtube) {
      addYoutubeMedia(
        {
          youtubeUrl: `https://www.youtube.com/embed/${youtube}`
        }, {
          onSuccess: (response) => {
            enqueueSnackbar(response.message, { variant: 'success' });
            fetchMedia();
            closeModal();
          },
          onError: () => {
            enqueueSnackbar('Error: Please try again later', { variant: 'error' });
          }
        }
      );
    } else {
      enqueueSnackbar("Invalid youtube link", { variant: "error" });
    }

  }

  const isLoading = useMemo(() => {
    return !!isAddingMedia;
  }, [isAddingMedia]);

  return (
    <div>
      <div className="row center-button">
          {
            (media?.length < 4 && !isPublic) &&
            <button className='add-button' onClick={openModel}>Add</button>
          }

          <Modal show={modalIsOpen} onHide={closeModal}>
            <Modal.Header className="border-0" closeButton>
              <Modal.Title>
                <h2
                  style={{
                    fontSize: "18px",
                    lineHeight: "32px",
                    textAlign: "left",
                    marginBottom: "1px"
                  }}
                  >
                  Add Media
                </h2>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <form autoComplete="off" onSubmit={handleAddMedia}>
                <div className="row">
                  <div className="col-lg-12 my-3">
                    <Typography
                      text="Copy the youtube url you would like to add to your profile."
                      color="#536474"
                      fontWeight="400"
                      fontSize="12px"
                      lineHeight="16px"
                      textAlign="left"
                      padding="0"
                    />

                    <input
                      type="text"
                      className="form-control"
                      id="youtubeLink"
                      name="youtubeLink"
                      aria-describedby="Youtube Link"
                      onChange={handleInputChange}
                      placeholder="Link"
                      value={inputValues.youtubeLink}
                      required
                    />
                  </div>

                  <div className="row">
                    <div className="col-lg-12 col-lg-12 center-button">
                      <CustomGradientButton
                        text="Add"
                        fontColor="#fff"
                        width="150px"
                        borderRadius="5px"
                        backgroundImage="linear-gradient(#E5AD5A, #D26E2A)"
                        border="0"
                        fontSize="16px"
                        onClick={handleAddMedia}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </Modal.Body>
          </Modal>
        </div>

      <div className="row">
        { isLoading &&
          <LoadSpinner />
        }

        <div className='media-container'>
          {
            media?.length < 1 && <p>No Media Data</p>
          }
          {
            media?.map(({ id, youtubeUrl }) => {
              return (
                <MediaFrame
                  key={id}
                  youtubeLink={youtubeUrl}
                  id={id}
                  isPublic={isPublic}
                  fetchMedia={fetchMedia}
                />
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Media;
