import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Typography from "../../../../../components/common/Typography";
import CustomGradientButton from "../../../../../components/common/CustomGradientButton";
import { size } from '../../../../../styles/size';
import MailImg from '../images/mail.png';
import './index.scss';


export const ForgotPassword = () => {
  const [isCompleted] = useState(false);

    return (
      <div className='forgot-password'>
        {
          !isCompleted ?
            <div className='forgot-password__container'>
              <Typography
                text='Forgot Password'
                fontSize={size.size05}
                fontWeight='600'
                className='forgot-password__title'
              />
              <Typography
                text='Enter your email to reset password'
                fontSize={size.size04}
                className='forgot-password__sub-title'
              />

              <form className='forgot-password__form'>
                <Typography
                  text='Email Address'
                  textAlign='left'
                  fontSize={'14px'}
                />
                <input
                  type="email"
                  className=""
                  id="email"
                  name="email"
                  aria-describedby="Email"
                  onChange={() => {}}
                  required
                />

                <CustomGradientButton
                  text="Recover Password"
                  fontColor="#fff"
                  width="100%"
                  borderRadius="5px"
                  backgroundImage="linear-gradient(#E5AD5A, #D26E2A)"
                  border="0"
                  btnLooading={false}
                  margin={'0 0 24px 0'}
                />
              </form>

              <Typography
                text={
                    <Link to='/new-login' className='forgot-password__login'>Log In</Link>
                }
              />
            </div> :
            <div className='forgot-password__container'>
              <Typography
                text='Check Your Inbox'
                fontSize={size.size05}
                fontWeight='600'
                className='forgot-password__title'
              />
              <Typography
                text='Enter your email to reset password'
                fontSize={size.size04}
                className='forgot-password__sub-title'
              />

              <img src={MailImg} alt='Mail Image' className='forgot-password__mail' />
            </div>
        }
      </div>
    );
}
