import { useQuery } from "react-query";
import { QueryKeys } from "../../utils/queryKeys";
import { apiClient } from "../../utils/ApiClient";
import { Endpoints } from "../../utils/endpoints";


const getJobSeekerUserDetails = async () => {
  return await apiClient.get(Endpoints.jobSeekerUserMe);
}

export const useJobSeekerUserDetails = () => {
  return useQuery({
    queryKey: QueryKeys.jobSeekersUserDetails,
    queryFn: getJobSeekerUserDetails,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });
}
