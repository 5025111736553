import React, {useContext, useState} from "react";
import { enqueueSnackbar } from "notistack";
import Typography from "../../components/common/Typography";
import { Navbar } from "../../components/v2/navbar";
import Footer from "../../components/layout/Footer";
import { AuthenticationContext } from "../../context/Authentication";
import { ProfileContext } from "../../context/Profile";
import "./index.css";

const ContactUs = () => {

  const [ inputValues, setInput ] = useState({
    fullname: '',
    email: '',
    comment: ''
  });

  const { handleContactUs } = useContext(AuthenticationContext);
  const { updateLoading, loading } = useContext(ProfileContext);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setInput((state) => ({ ...state, [name]: value}));
  };

  const handleContactUsSubmit = async (event) => {
    event.preventDefault();
    updateLoading(true);
    const { success, message } = await handleContactUs(inputValues)

    if (success) {
      enqueueSnackbar(message, { variant: "success" });
      setInput({
        fullname: '', email: '', comment: ''
      });
    } else {
      enqueueSnackbar(message, { variant: "error" });
    }
    updateLoading(false);
  }

  return (
    <div>
      <Navbar />
      <div className="contact-us-main">
        <Typography
          text="Contact Us"
          color="#fff"
          fontWeight="800"
          fontSize="50px"
        />
      </div>

      <section className="pb-5 pt-3">
        <div className="container pt-3">
          <div className="row">
            <div className="col-lg-7 offset-lg-1 text-start order-lg-last my-3">
              <form autoComplete="off" onSubmit={handleContactUsSubmit}>
                <div className="row">
                  <div className="mb-3 col-sm-12 col-lg-6">
                    <label htmlFor="fullname" className="form-label">
                      Full Name
                    </label>
                    <input type="text" className="form-control"
                           id="fullname" onChange={handleInputChange}
                           name="fullname" required value={inputValues.fullname} />
                  </div>
                  <div className="mb-3 col-sm-12 col-lg-6">
                    <label htmlFor="email" className="form-label">
                      Email Address
                    </label>
                    <input type="email" className="form-control"
                           id="email" onChange={handleInputChange} name="email"
                           required  value={inputValues.email} />
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="comment" className="form-label">
                    How can we help you?
                  </label>
                  <textarea
                    className="form-control"
                    id="comment"
                    rows="5"
                    placeholder="Comments..."
                    onChange={handleInputChange}
                    name="comment"
                    required
                    value={inputValues.comment}
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary-color py-3 px-5 mt-3"
                  disabled={loading}
                >
                  Send
                </button>
              </form>
            </div>

            <div className="col-lg-4 my-3" style={{ textAlign: "left" }}>
              <h6 className="mb-4" style={{ color: "#536474" }}>
                We're here to help.
              </h6>

              <h5 className="mb-3">Email Address </h5>

              <p
                text=""
                textAlign="left"
                fontWeight="normal"
                fontSize="18px"
                margin="0 0 10px 0"
              >
                info@showkonnect.com
              </p>

              <h5 className="mb-3">Office Address</h5>

              <p>Lekki, Lagos, Nigeria</p>
            </div>
          </div>
        </div>
      </section>

      <div className="container pb-5">
        <div className="row">
          <Typography
            text="Frequently Asked Questions"
            fontWeight="600"
            fontSize="40px"
            lineHeight="1.2em"
            margin="60px 0 60px 0"
          />

          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  style={{ fontWeight: "bold" }}
                >
                  How do I get paid on Showkonnect?
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body" style={{ textAlign: "left" }}>
                  Payouts on Showkonnect happen via Escrow. Once both parties (client and entertainer)
                  sign off on the project, the client pays the agreed fee and we hold it in Escrow to be released
                  to the entertainer after the project is completed.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                  style={{ fontWeight: "bold" }}
                >
                  How do I post a job on Showkonnect?
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body" style={{ textAlign: "left" }}>
                  Anyone can post a job on Showkonnect, regardless of their profile type (entertainer or client).
                  Just click the "post a job" button and follow the prompt. Be sure to select the appropriate filters
                  and categories so that your job posting is targeted at entertainers in those specific categories.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                  style={{ fontWeight: "bold" }}
                >
                  Can I join Showkonnect from my country?
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body" style={{ textAlign: "left" }}>
                  We're currently operational in the following locations:...
                  We're always adding new locations.
                  Please drop your email and we'll let you know when we are live in your location.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                  style={{ fontWeight: "bold" }}
                >
                  Can I post multiple jobs at the same time?
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body" style={{ textAlign: "left" }}>
                  Yes, you are allowed to post multiple jobs at the same time within and beyond a particular
                  skill category.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ContactUs;
